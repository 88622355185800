import axios from 'axios';
import store from '@/store';
import { UNAUTHORIZED } from 'http-status-codes';
// import router from '@/router';

const fiveMinuteTimeout: number = 300000;
const reportingTimeout: number = 960000; // 16 minutes
const crazyLongTimeout: number = 1800000; // 30 minutes

const addSpirionHeader = (config: any, headerName: string, value: string) => {
  if (!config || !headerName || !value) {
    return;
  }
  config.headers[`SP-${headerName}`] = value;
};

const $axios = axios.create({
  // TODO: set this back to an acceptable timeout (30s) once the APIs are fixed and no longer require this much time.
  timeout: fiveMinuteTimeout,
});

$axios.interceptors.response.use(response => response, (error) => {
  if (error.response.status === UNAUTHORIZED) {
    store.dispatch('session/inactiveLogout');
    /**
     * use if you want to redirect user to the
     * 401 page upon first 401 or 403 api response;
     * this will not prevent subsequent requests from being made
     */
    // if (router.currentRoute.name !== 'access-denied') {
    //   router.push({ name: 'access-denied' });
    // }
  }
  return Promise.reject(error);
});

$axios.interceptors.request.use((config) => {
  const token = store.getters['oidc/oidcAccessToken'];
  // these headers are not required for any API call to be successful so do not prevent the call in case of an exception
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  try {
    if (config && config.url && config.url.length) {
      const isReportLoadApi = config.url.indexOf('api/Report/LoadReportData') >= 0 || config.url.indexOf('api/Report/LoadReportPreviewData') >= 0;
      // scan results page timeout is 30 minutes, reports loading is 15, all others use the default five minutes.
      config.timeout = config.url.indexOf('api/Search/LoadData') >= 0 ? crazyLongTimeout : isReportLoadApi ? reportingTimeout : fiveMinuteTimeout;
    }

    addSpirionHeader(config, 'ConsoleUrl', window.location.href);
    addSpirionHeader(config, 'ConsoleVersion', store.getters['session/getAppVersion']);
  }
  catch (err) {
    store.dispatch('snackbar/setMessage', {
      text: err,
      status: 'error',
    });
  }
  return config;
});

export default $axios;
