import TypeHelper from '@/helpers/TypeHelper';
import StringHelper from './StringHelper';

export default abstract class ArrayHelper {
  // use with Array.filter
  public static unique(value, index, self): boolean
  {
    return self.indexOf(value) === index;
  }

  public static isArraySet(obj: any[]): boolean
  {
    return obj && obj.length > 0;
  }

  public static arraysEqual<T>(arr1: T[], arr2: T[], comparer: (a: T, b: T) => boolean = null, sorter: (a: T[]) => T[] = null): boolean
  {
    if (!arr1 && !arr2)
    {
      return true;
    }
    if (!arr1 || !arr2)
    {
      return false;
    }
    if (arr1.length !== arr2.length)
    {
      return false;
    }

    if (!comparer)
    {
      comparer = (a: T, b: T) => a === b;
    }

    const hasSorter = !TypeHelper.isNull(sorter);
    const sortedArr1 = hasSorter ? sorter(arr1) : arr1;
    const sortedArr2 = hasSorter ? sorter(arr2) : arr2;

    let ret = true;
    for (let i = 0; i < sortedArr1.length; i++)
    {
      if (!comparer(sortedArr1[i], sortedArr2[i]))
      {
        ret = false;
        break;
      }
    }
    return ret;
  }

  public static trimToUniqueNumbers<T>(input: T[], getNumber: (item: T) => number): number[]
  {
    if (!ArrayHelper.isArraySet(input) || !getNumber)
    {
      return [];
    }
    // using a dictionary with forEach and parseInt for all keys has a significant performance increase (E.g. 12s to <1s with ~50,000 rows),
    // ..over using (for example) input.filter({ condition}).map({ return numberProperty}).filter(ArrayHelper.unique).
    const uniqueItems = {};
    input.forEach((i: T) =>
    {
      const value = getNumber(i);
      if (!TypeHelper.isNull(value))
      {
        uniqueItems[value] = null; // dictionary value is not needed
      }
    });

    const keys = Object.keys(uniqueItems);
    return ArrayHelper.isArraySet(keys) ? keys.map((key: string) => parseInt(key, 10)) : [];
  }

  /**
   * @description sort an array by an array of fields
   * @param {Array} arr array to be sorted
   * @param {string} fields array of fields to be sorted in sequential order
   */
  public static sortByFields(arr: any[], fields: string[]): any[] {
    if (!ArrayHelper.isArraySet(arr)) {
      return [];
    }
    if (!ArrayHelper.isArraySet(fields)) {
      return arr;
    }
    fields.forEach(field => {
      arr.sort((a, b) => (a[field] && b[field] ? StringHelper.lowerCase(a[field], true) > StringHelper.lowerCase(b[field], true) ? 1 : -1 : -1));
    });
    return arr;
  }

  /**
   * @description get all permutations of an array
   * @param {Array} arr array to be permutated
   */
  public static permutate(arr: any[]): any[][] {
    const length = arr.length;
    const result = [arr.slice()];
    const c = new Array(length).fill(0);
    let i = 1;
    let k: any;
    let p: any[];

    while (i < length) {
      if (c[i] < i) {
        k = i % 2 && c[i];
        p = arr[i];
        arr[i] = arr[k];
        arr[k] = p;
        ++c[i];
        i = 1;
        result.push(arr.slice());
      } else {
        c[i] = 0;
        ++i;
      }
    }
    return result;
  }

  /**
   * @param {Array} arr array to be chunked
   * @param {number} chunk number of chunks to create
   */
  public static splitIntoChunks(arr: any[], chunk: number = 2): any[][] {
    const n = arr.length / chunk;
    const ret = [];
    for (let i = 0; i < arr.length; i += n) {
      ret.push(arr.slice(i, i + n));
    }
    return ret;
  }
}
