export default [
  {
    path: '/oidc-callback', // Needs to match redirect_uri in you oidcSettings
    name: 'oidcCallback',
    component: () => import('@/pages/oidc/OidcCallback.vue'),
  },
  {
    path: '/signin-error', // Needs to match redirect_uri in you oidcSettings
    name: 'oidcCallbackError',
    component: () => import('@/pages/oidc/OidcCallbackError.vue'),
    meta: {
      isPublic: true,
    },
  }
]
