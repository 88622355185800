import EnumUtility from '@/enums/EnumUtility';
import TypeHelper from './TypeHelper';
import ArrayHelper from './ArrayHelper';
import { NameValuePair, Pair } from './UtilityClasses';
import StringHelper from './StringHelper';

export const allTargetsEndpointId = 2147483647;
export const allTargetsTagId = 0;

// Maps to server side enumeration for loading/setting user permissions
export enum PermissionType {
  Code = 0,
  TagResult = 1,
  EndpointResult = 2,
  TagEdit = 3,
  Report = 4,
  Policy = 5,
  PolicyList = 6,
  CustomType = 7,
  EndpointEdit = 8,
  EndpointOwner = 9,
  Scan = 10,
  Playbook = 11,
  PlaybookAction = 12
}

// Flags enumeration, all values are double the previous value
export enum BasicPermissions {
  None = 0,
  View = 1,
  Edit = 2
}

export abstract class BasicPermissionsUtil {
  public static toDisplay(type: BasicPermissions): string {
    const getString = (t: BasicPermissions): string => {
      if (TypeHelper.isNull(t)) {
        t = 0;
      }

      switch (t) {
        default:
          return BasicPermissions[<number>t];
      }
    };

    return EnumUtility.flagsEnumToDisplay(type, BasicPermissions, getString);
  }

  public static getOptions(includeInherited: boolean = true): NameValuePair<number>[] {
    // The UI does not allow explicit assigning of the Edit permission.  E.g. View and Manage are allowed where manage is both view and edit summed.
    const baseOptions: NameValuePair<number>[] = [
      new NameValuePair('None', BasicPermissions.None),
      new NameValuePair('Read', BasicPermissions.View),
      // eslint-disable-next-line
      new NameValuePair('Manage', BasicPermissions.View | BasicPermissions.Edit)
    ];

    if (!includeInherited) {
      return baseOptions;
    }
    return [new NameValuePair<number>('Inherited', null), ...baseOptions];
  }
}

// Flags enumeration. All values are double the previous value
export enum ResultsPermissions {
  // For Target and Tag Result Permissions
  // Export, UnmaskExport, ViewIncidentDAta are not used on the UI but the placeholders of 2, 8, 16 must remain as they match an API enumeration.
  None = 0,
  View = 1,
  //Export = 2,
  UnmaskView = 4,
  //UnmaskExport = 8,
  //ViewIncidentData = 16,
  PlaybookOverride = 32
  //All = ResultsPermissions.View
  //+ ResultsPermissions.Export
  //+ ResultsPermissions.UnmaskView
  //+ ResultsPermissions.UnmaskExport
  //+ ResultsPermissions.ViewIncidentData
  //+ ResultsPermissions.PlaybookOverride
}

export abstract class ResultsPermissionsUtil {
  public static toDisplay(type: ResultsPermissions): string {
    const getString = (t: ResultsPermissions): string => {
      if (TypeHelper.isNull(t)) {
        t = 0;
      }

      switch (t) {
        case ResultsPermissions.UnmaskView:
          return 'Unmask View';
        //case ResultsPermissions.UnmaskExport:
        //  return 'Unmask Export';
        //case ResultsPermissions.ViewIncidentData:
        //  return 'View Activity Watcher Data';
        case ResultsPermissions.PlaybookOverride:
          return 'Playbook Override'
        default:
          return ResultsPermissions[<number>t];
      }
    };

    return EnumUtility.flagsEnumToDisplay(type, ResultsPermissions, getString);
  }

  public static getOptions(includeInherited: boolean = true): NameValuePair<number>[] {
    const baseOptions = EnumUtility.getNameValuePairs(ResultsPermissions, null, ResultsPermissionsUtil.toDisplay);
    if (!includeInherited) {
      return baseOptions;
    }
    return [new NameValuePair<number>('Inherited', null), ...baseOptions];
  }

  public static getAdminPermissionValue(): ResultsPermissions {
    // eslint-disable-next-line
    return ResultsPermissions.View | ResultsPermissions.UnmaskView | ResultsPermissions.PlaybookOverride; // | ResultsPermissions.ViewIncidentData
  }
}

// Flags enumeration. All values are double the previous value
export enum TagEditPermissions {
  None = 0,
  Modify = 1,
  AddRemoveTags = 2,
  AddRemoveTargets = 4,
  //All = TagEditPermissions.Modify + TagEditPermissions.AddRemoveTags + TagEditPermissions.AddRemoveTargets
}

export abstract class TagEditPermissionsUtil {
  public static toDisplay(type: TagEditPermissions): string {
    const getString = (t: TagEditPermissions): string => {
      if (t === undefined) {
        t = 0;
      }

      switch (t) {
        case TagEditPermissions.AddRemoveTags:
          return 'Add/Remove Tags';
        case TagEditPermissions.AddRemoveTargets:
          return 'Add/Remove Targets';
        default:
          return TagEditPermissions[<number>t];
      }
    };

    return EnumUtility.flagsEnumToDisplay(type, TagEditPermissions, getString);
  }

  public static getOptions(includeInherited: boolean = true): NameValuePair<number>[] {
    const baseOptions = EnumUtility.getNameValuePairs(TagEditPermissions, null, TagEditPermissionsUtil.toDisplay);
    if (!includeInherited) {
      return baseOptions;
    }
    return [new NameValuePair<number>('Inherited', null), ...baseOptions];
  }
}

// Flags enumeration. All values are double the previous value
export enum TargetEditPermissions {
  None = 0,
  Modify = 1
}

export abstract class TargetEditPermissionsUtil {
  public static toDisplay(type: TargetEditPermissions): string {
    const getString = (t: TargetEditPermissions): string => {
      if (t === undefined) {
        t = 0;
      }

      switch (t) {
        default:
          return TargetEditPermissions[<number>t];
      }
    };

    return EnumUtility.flagsEnumToDisplay(type, TargetEditPermissions, getString);
  }

  public static getOptions(includeInherited: boolean = true): NameValuePair<number>[] {
    const baseOptions = EnumUtility.getNameValuePairs(TargetEditPermissions, null, TargetEditPermissionsUtil.toDisplay);
    if (!includeInherited) {
      return baseOptions;
    }
    return [new NameValuePair<number>('Inherited', null), ...baseOptions];
  }
}

export class RolePermissions {
  public tagEditPermissions: Pair<number, TagEditPermissions>[] = [];

  public tagResultsPermissions: Pair<number, ResultsPermissions>[] = [];

  public targetEditPermissions: Pair<number, TargetEditPermissions>[] = [];

  public targetResultsPermissions: Pair<number, ResultsPermissions>[] = [];

  constructor(obj: any = null) {
    if (TypeHelper.isNull(obj)) {
      return;
    }
    /*
     * API obj returns arrays:
     * tagsPermissions -> maps to tagResultsPermissions
     * tagsEditPermissions -> maps to tagEditPermissions
     * endpointsPermissions -> maps to targetResultsPermissions
     * endpointEditPermissions -> maps to targetEditPermissions
    */

    this.tagResultsPermissions = ArrayHelper.isArraySet(obj.tagsPermissions)
      ? (obj.tagsPermissions as any[]).map(p => new Pair<number, ResultsPermissions>(p.first, p.second))
      : [];

    this.tagEditPermissions = ArrayHelper.isArraySet(obj.tagsEditPermissions)
      ? (obj.tagsEditPermissions as any[]).map(p => new Pair<number, TagEditPermissions>(p.first, p.second))
      : [];

    this.targetResultsPermissions = ArrayHelper.isArraySet(obj.endpointsPermissions)
      ? (obj.endpointsPermissions as any[]).map(p => new Pair<number, ResultsPermissions>(p.first, p.second))
      : [];

    this.targetEditPermissions = ArrayHelper.isArraySet(obj.endpointEditPermissions)
      ? (obj.endpointEditPermissions as any[]).map(p => new Pair<number, TargetEditPermissions>(p.first, p.second))
      : [];
  }
}

export class UiElementPermissions {
  public scanPermissions: Pair<string, BasicPermissions>[] = []; // guid id, permissions value

  public playbookPermissions: Pair<string, BasicPermissions>[] = [];

  public reportPermissions: Pair<string, BasicPermissions>[] = [];
}

interface IUserPermissionServerItem {
  elementId: number;
  type: PermissionType;
  value: number;
  roleIds: string[];
}

export interface IUserPermissionItem {
  roleIds: string[];
  editSetByUser: boolean;
  resultSetByUser: boolean;
}

export class UserPermissions {
  public permissions: IUserPermissionServerItem[];

  constructor(initData: IUserPermissionServerItem[] = []) {
    this.permissions = ArrayHelper.isArraySet(initData) ? initData : [];
  }
}

export class RoleTargetPermissionItem {
  public roleId: string;

  public resultPermission: ResultsPermissions;

  public editPermission: TargetEditPermissions;

  constructor(roleId: string, resultPermission: ResultsPermissions, editPermission: TargetEditPermissions) {
    this.roleId = roleId;
    this.resultPermission = resultPermission; // can be null.  e.g. inherited
    this.editPermission = editPermission; // can be null. e.g. inherited
  }
}

export class RoleTargetPermissions {
  public permissions: RoleTargetPermissionItem[] = [];

  constructor(obj: any = null) {
    if (!obj) {
      return;
    }
    this.permissions = ArrayHelper.isArraySet(obj) ? obj.map(item => new RoleTargetPermissionItem(item.roleId, item.endpointResultPermissions, item.endpointEditPermissions)) : [];
  }
}

export class RoleTagPermissionItem {
  public roleId: string;

  public resultPermission: ResultsPermissions;

  public editPermission: TagEditPermissions;

  constructor(roleId: string, resultPermission: ResultsPermissions, editPermission: TagEditPermissions) {
    this.roleId = roleId;
    this.resultPermission = resultPermission; // can be null.  e.g. inherited
    this.editPermission = editPermission; // can be null. e.g. inherited
  }
}

export class RoleTagPermissions {
  public permissions: RoleTagPermissionItem[] = [];

  constructor(obj: any = null) {
    if (!obj) {
      return;
    }
    this.permissions = ArrayHelper.isArraySet(obj) ? obj.map(item => new RoleTagPermissionItem(item.roleId, item.endpointResultPermissions, item.tagEditPermissions)) : [];
  }
}

export class UserTagPermissionItem {
  public userId: string;

  public resultPermission: ResultsPermissions;

  public editPermission: TagEditPermissions;

  public isUserSet: boolean;

  constructor(userId: string, resultPermission: ResultsPermissions, editPermission: TagEditPermissions, isUserSet: boolean) {
    this.userId = userId;
    this.resultPermission = resultPermission;
    this.editPermission = editPermission;
    this.isUserSet = isUserSet;
  }
}

export class UserTagPermissions {
  public permissions: UserTagPermissionItem[] = [];

  constructor(obj: any = null) {
    if (!obj) {
      return;
    }
    this.permissions = ArrayHelper.isArraySet(obj)
      ? obj.map(item => new UserTagPermissionItem(item.userId, item.endpointResultPermissions, item.tagEditPermissions, !ArrayHelper.isArraySet(item.roleIds))) : [];
  }
}

export class UserTargetPermissionItem {
  public userId: string;

  public resultPermission: ResultsPermissions;

  public editPermission: TargetEditPermissions;

  public isUserSet: boolean;

  constructor(userId: string, resultPermission: ResultsPermissions, editPermission: TargetEditPermissions, isUserSet: boolean) {
    this.userId = userId;
    this.resultPermission = resultPermission;
    this.editPermission = editPermission;
    this.isUserSet = isUserSet;
  }
}

export class UserTargetPermissions {
  public permissions: UserTargetPermissionItem[] = [];

  constructor(obj: any = null) {
    if (!obj) {
      return;
    }
    this.permissions = ArrayHelper.isArraySet(obj)
      ? obj.map(item => new UserTargetPermissionItem(item.userId, item.endpointResultPermissions, item.endpointEditPermissions, !ArrayHelper.isArraySet(item.roleIds))) : [];
  }
}

export class TagPermissionInfo {
  public editPermission: TagEditPermissions = TagEditPermissions.None;

  public resultPermission: ResultsPermissions = ResultsPermissions.None;

  public static createAdminInfo(): TagPermissionInfo {
    // eslint-disable-next-line
    return new TagPermissionInfo(TagEditPermissions.Modify | TagEditPermissions.AddRemoveTags | TagEditPermissions.AddRemoveTargets, ResultsPermissionsUtil.getAdminPermissionValue());
  }

  constructor(editPermission: TagEditPermissions, resultPermission: ResultsPermissions) {
    this.editPermission = editPermission;
    this.resultPermission = resultPermission;
  }

  public get editPermissionDisplay(): string { return TypeHelper.isNull(this.editPermission) ? 'Inherited' : TagEditPermissionsUtil.toDisplay(this.editPermission); }

  public get resultPermissionDisplay(): string { return TypeHelper.isNull(this.resultPermission) ? 'Inherited' : ResultsPermissionsUtil.toDisplay(this.resultPermission); }
}

export class UserTagPermissionInfo extends TagPermissionInfo implements IUserPermissionItem {
  public roleIds: string[] = [];

  public editSetByUser: boolean = false;

  public resultSetByUser: boolean = false;

  public userEditPermission: TagEditPermissions = null;

  public userResultPermission: ResultsPermissions = null;

  constructor(editPermissionData: IUserPermissionServerItem, resultPermissionData: IUserPermissionServerItem) {
    super(editPermissionData ? editPermissionData.value : null, resultPermissionData ? resultPermissionData.value : null);

    if (editPermissionData && ArrayHelper.isArraySet(editPermissionData.roleIds)) {
      this.roleIds = editPermissionData.roleIds;
    }
    if (resultPermissionData && resultPermissionData.roleIds) {
      resultPermissionData.roleIds.filter(id => !this.roleIds.includes(id)).forEach(id => this.roleIds.push(id));
    }
    // When edit or result permission data is null then there is no explicit permission saved for this tag. E.g. the effective user ...
    // ... permission is inherited from the role by default when no explicit permission is saved.  When the data is not null then ...
    // ... the roleIds will be set when the permission is set at the role level.
    this.editSetByUser = !TypeHelper.isNull(editPermissionData) && !ArrayHelper.isArraySet(editPermissionData.roleIds);
    this.resultSetByUser = !TypeHelper.isNull(resultPermissionData) && !ArrayHelper.isArraySet(resultPermissionData.roleIds);

    // The base class, editPermission and resultPermission props, holds the effective permissions. E.g. this is what displays in the ...
    // ... grids and that can come from role or user set permissions.  When either is user set we apply the effective permission ...
    // ... values to the userEditPermission / userResultPermission props.  These two properties are used to set the drop down ...
    // ... values on the edit permissions dialog.  E.g. the drop downs should always reflect the 'user' set permissions not the ...
    // ... effective permission.
    if (this.editSetByUser) {
      this.userEditPermission = this.editPermission;
    }
    if (this.resultSetByUser) {
      this.userResultPermission = this.resultPermission;
    }
  }
}

export class TargetPermissionInfo {
  public editPermission: TargetEditPermissions = TargetEditPermissions.None;

  public resultPermission: ResultsPermissions = ResultsPermissions.None;

  public static createAdminInfo(): TargetPermissionInfo {
    // eslint-disable-next-line
    return new TargetPermissionInfo(TargetEditPermissions.Modify, ResultsPermissionsUtil.getAdminPermissionValue());
  }

  constructor(editPermission: TargetEditPermissions, resultPermission: ResultsPermissions) {
    this.editPermission = editPermission;
    this.resultPermission = resultPermission;
  }

  public get editPermissionDisplay(): string { return TypeHelper.isNull(this.editPermission) ? 'Inherited' : TargetEditPermissionsUtil.toDisplay(this.editPermission); }

  public get resultPermissionDisplay(): string { return TypeHelper.isNull(this.resultPermission) ? 'Inherited' : ResultsPermissionsUtil.toDisplay(this.resultPermission); }
}

export class UserTargetPermissionInfo extends TargetPermissionInfo implements IUserPermissionItem {
  public roleIds: string[] = [];

  public editSetByUser: boolean = false;

  public resultSetByUser: boolean = false;

  public userEditPermission: TargetEditPermissions = null;

  public userResultPermission: ResultsPermissions = null;

  constructor(editPermissionData: IUserPermissionServerItem, resultPermissionData: IUserPermissionServerItem) {
    super(editPermissionData ? editPermissionData.value : null, resultPermissionData ? resultPermissionData.value : null);

    if (editPermissionData && ArrayHelper.isArraySet(editPermissionData.roleIds)) {
      this.roleIds = editPermissionData.roleIds;
    }
    if (resultPermissionData && resultPermissionData.roleIds) {
      resultPermissionData.roleIds.filter(id => !this.roleIds.includes(id)).forEach(id => this.roleIds.push(id));
    }
    // When edit or result permission data is null then there is no explicit permission saved for this target. E.g. the effective user ...
    // ... permission is inherited from the role by default when no explicit permission is saved.  When the data is not null then ...
    // ... the roleIds will be set when the permission is set at the role level.
    this.editSetByUser = !TypeHelper.isNull(editPermissionData) && !ArrayHelper.isArraySet(editPermissionData.roleIds);
    this.resultSetByUser = !TypeHelper.isNull(resultPermissionData) && !ArrayHelper.isArraySet(resultPermissionData.roleIds);

    // The base class, editPermission and resultPermission props, holds the effective permissions. E.g. this is what displays in the ...
    // ... grids and that can come from role or user set permissions.  When either is user set we apply the effective permission ...
    // ... values to the userEditPermission / userResultPermission props.  These two properties are used to set the drop down ...
    // ... values on the edit permissions dialog.  E.g. the drop downs should always reflect the 'user' set permissions not the ...
    // ... effective permission.
    if (this.editSetByUser) {
      this.userEditPermission = this.editPermission;
    }
    if (this.resultSetByUser) {
      this.userResultPermission = this.resultPermission;
    }
  }
}

export class AllTargetPermissionInfo {
  public editPermission: TargetEditPermissions = TargetEditPermissions.None;

  public resultPermission: ResultsPermissions = ResultsPermissions.None;

  constructor(editPermission: TargetEditPermissions, resultPermission: ResultsPermissions) {
    // The All Targets tag does not allow inherited/null for either permission
    this.editPermission = TypeHelper.isNull(editPermission) ? TargetEditPermissions.None : editPermission;
    this.resultPermission = TypeHelper.isNull(resultPermission) ? ResultsPermissions.None : resultPermission;
  }

  public get editPermissionDisplay(): string { return TargetEditPermissionsUtil.toDisplay(this.editPermission); }

  public get resultPermissionDisplay(): string { return ResultsPermissionsUtil.toDisplay(this.resultPermission); }
}

export class AllTargetUserPermissionInfo extends AllTargetPermissionInfo implements IUserPermissionItem {
  public roleIds: string[] = [];

  public editSetByUser: boolean = false;

  public resultSetByUser: boolean = false;

  public userEditPermission: TargetEditPermissions = null;

  public userResultPermission: ResultsPermissions = null;

  constructor(userTargetPermission: UserTargetPermissionInfo, userTagPermission: UserTagPermissionInfo) {
    super(userTargetPermission.editPermission, userTagPermission.resultPermission);

    if (ArrayHelper.isArraySet(userTargetPermission.roleIds)) {
      this.roleIds = userTargetPermission.roleIds;
    }
    if (ArrayHelper.isArraySet(userTagPermission.roleIds)) {
      userTagPermission.roleIds.filter(id => !this.roleIds.includes(id)).forEach(id => this.roleIds.push(id));
    }

    this.editSetByUser = userTargetPermission.editSetByUser;
    this.resultSetByUser = userTagPermission.resultSetByUser;

    // The base class, editPermission and resultPermission props, holds the effective permissions. E.g. this is what displays in the ...
    // ... grids and that can come from role or user set permissions.  When either is user set we apply the effective permission ...
    // ... values to the userEditPermission / userResultPermission props.  These two properties are used to set the drop down ...
    // ... values on the edit permissions dialog.  E.g. the drop downs should always reflect the 'user' set permissions not the ...
    // ... effective permission.
    if (this.editSetByUser) {
      this.userEditPermission = this.editPermission;
    }
    if (this.resultSetByUser) {
      this.userResultPermission = this.resultPermission;
    }
  }
}

interface ISavePermissionPayload {
  elementId: string; // e.g. role or user Id
  id: any;
  resultsPermissions: number;
  editPermissions: number;
}

interface ISaveUidPermissionPayload {
  userOrRoleId: string;
  elementId: string; // E.g. scan, playbook, or report id
  type: PermissionType;
  permission: BasicPermissions;
}

export class UiElementPermissionsInfo {
  public rolePermissionsMap: {} = {};

  public userPermissionsMap: {} = {};

  public optionDisplayMap: {} = {};

  constructor() {
    BasicPermissionsUtil.getOptions().forEach(pair => {
      if (pair.value !== null) { // e.g. exclude Inherited from the map
        this.optionDisplayMap[pair.value] = pair.name;
      }
    });
  }
}

export class PermissionManager {
  private rolePermissions: RolePermissions = null;

  private userPermissions: UserPermissions = null;

  private tagPermissionsForRoles: RoleTagPermissions = null;

  private targetPermissionsForRoles: RoleTargetPermissions = null;

  private tagPermissionsForUsers: UserTagPermissions = null;

  private targetPermissionsForUsers: UserTargetPermissions = null;

  private roleUiElementPermissions: UiElementPermissions = null;

  private userUiElementPermissions: UiElementPermissions = null;

  private static isValidUiElementPermissionType(type: PermissionType): boolean {
    const validTypes = [PermissionType.Scan, PermissionType.Playbook, PermissionType.Report];
    if (TypeHelper.isNull(type) || !validTypes.some(t => t === type)) {
      return false;
    }
    return true;
  }

  private static buildRequestParams(payload: ISavePermissionPayload, isUserPermission: boolean = false): string {
    const params: string[] = [`${(isUserPermission ? 'userId' : 'roleId')}=${payload.elementId}`, `id=${payload.id}`];
    if (!TypeHelper.isNull(payload.resultsPermissions)) {
      params.push(`resultsPermissions=${payload.resultsPermissions}`);
    }
    if (!TypeHelper.isNull(payload.editPermissions)) {
      params.push(`editPermissions=${payload.editPermissions}`);
    }
    return params.join('&');
  }

  private static buildUidRequestParams(payload: ISaveUidPermissionPayload, isUserPermission: boolean = false): string {
    const params: string[] = [`${(isUserPermission ? 'userId' : 'roleId')}=${payload.userOrRoleId}`, `type=${payload.type}`, `elementId=${payload.elementId}`];
    if (!TypeHelper.isNull(payload.permission)) {
      params.push(`permission=${payload.permission}`);
    }
    return params.join('&');
  }

  public static async updateRolePermissionsForTag($api: any, $service: any, roleId: string, tagId: number, editPermissions: TagEditPermissions, resultsPermissions: ResultsPermissions): Promise<boolean> {
    // A null permission value means to remove the permission on the back end.  E.g. when a user wants to remove a permission on the UI we will pass null to this method
    if (resultsPermissions === undefined) {
        resultsPermissions = null; // e.g. clears the permission
    }
    // The edit permission does not apply to the built in All Targets tag so regardless of what is ...
    // ... passed in we always clear the edit permission for this tag. In all other cases we set ...
    // ... undefined to null to ensure the permission is removed from the DB by the API
    if (editPermissions === undefined || tagId === allTargetsTagId) {
        editPermissions = null; // e.g. clears the permission
    }
    // The edit permission does not apply when the results permission is None so we force save None for the edit permissions ...
    // ... except for the All Targets tag as the tag edit permissions do not apply to this built in tag and are always ...
    // ... cleared above.
    if (resultsPermissions === ResultsPermissions.None && tagId !== allTargetsTagId) {
      editPermissions = TagEditPermissions.None;
    }

    const payload = {
      elementId: roleId,
      id: TypeHelper.isString(tagId) ? parseInt(tagId as any, 10) : tagId,
      resultsPermissions,
      editPermissions
    };

    const url = `${$service.SearchController_Api_Url}Permission/UpdateTagPermissionsByRole?${PermissionManager.buildRequestParams(payload)}`;
    const response = await $api.put(url, null, 'The Tag permissions were successfully updated.');
    return response && response.status === 200;
  }

  public static async removeRolePermissionsForTag($api: any, $service: any, roleId: string, tagId: number): Promise<boolean> {
    const payload = {
      elementId: roleId,
      id: TypeHelper.isString(tagId) ? parseInt(tagId as any, 10) : tagId,
      resultsPermissions: null,
      editPermissions: null,
    };

    const url = `${$service.SearchController_Api_Url}Permission/UpdateTagPermissionsByRole?${PermissionManager.buildRequestParams(payload)}`;
    const response = await $api.put(url, null, 'The Tag permissions were successfully removed.');
    return response && response.status === 200;
  }

  public static async updateRolePermissionsForTarget($api: any, $service: any, roleId: string, targetId: number, editPermissions: TargetEditPermissions, resultsPermissions: ResultsPermissions, suppressSuccessSnack: boolean = false): Promise<boolean> {
    // A null permission value means to remove the permission on the back end.  E.g. when a user wants to remove a permission on the UI we will pass null to this method.
    // The result permission should never be saved in the DB for the built in all targets id so it is always set to null.
    if (resultsPermissions === undefined || targetId === allTargetsEndpointId) {
      resultsPermissions = null; // e.g. clears the permission
    }
    // If the edit permission is undefined we force null to ensure the permission is removed from the DB.
    if (editPermissions === undefined) {
      editPermissions = null; // e.g. clears the permission
    }
    // The edit permission does not apply when the results permission is None so we force save None in all cases.
    if (resultsPermissions === ResultsPermissions.None) {
      editPermissions = TargetEditPermissions.None;
    }

    const payload = {
      elementId: roleId,
      id: TypeHelper.isString(targetId) ? parseInt(targetId as any, 10) : targetId,
      resultsPermissions,
      editPermissions
    };

    const url = `${$service.SearchController_Api_Url}Permission/UpdateEndpointPermissionsByRole?${PermissionManager.buildRequestParams(payload)}`;
    const response = await $api.put(url, null, suppressSuccessSnack ? null : 'The Target permissions were successfully updated.');
    return response && response.status === 200;
  }

  public static async removeRolePermissionsForTarget($api: any, $service: any, roleId: string, targetId: number, suppressSuccessSnack: boolean = false): Promise<boolean> {
    const payload = {
      elementId: roleId,
      id: TypeHelper.isString(targetId) ? parseInt(targetId as any, 10) : targetId,
      resultsPermissions: null,
      editPermissions: null,
    };

    const url = `${$service.SearchController_Api_Url}Permission/UpdateEndpointPermissionsByRole?${PermissionManager.buildRequestParams(payload)}`;
    const response = await $api.put(url, null, suppressSuccessSnack ? null : 'The Target permissions were successfully removed.');
    return response && response.status === 200;
  }

  public static async updateUserPermissionsForTag($api: any, $service: any, userId: string, tagId: number, editPermissions: TagEditPermissions, resultsPermissions: ResultsPermissions): Promise<boolean> {
    // A null permission value means to remove the permission on the back end.  E.g. when a user wants to remove a permission on the UI we will pass null to this method
    if (resultsPermissions === undefined) {
      resultsPermissions = null; // e.g. clears the permission
    }
    // The edit permission does not apply to the built in All Targets tag so regardless of what is ...
    // ... passed in we always clear the edit permission for this tag. In all other cases we set ...
    // ... undefined to null to ensure the permission is removed from the DB by the API
    if (editPermissions === undefined || tagId === allTargetsTagId) {
      editPermissions = null; // e.g. clears the permission
    }
    // The edit permission does not apply when the results permission is None so we force save None for the edit permissions ...
    // ... except for the All Targets tag as the tag edit permissions do not apply to this built in tag and are always ...
    // ... cleared above.
    if (resultsPermissions === ResultsPermissions.None && tagId !== allTargetsTagId) {
      editPermissions = TagEditPermissions.None;
    }

    const payload = {
      elementId: userId,
      id: TypeHelper.isString(tagId) ? parseInt(tagId as any, 10) : tagId,
      resultsPermissions,
      editPermissions
    };

    const url = `${$service.SearchController_Api_Url}Permission/UpdateTagPermissionsByUser?${PermissionManager.buildRequestParams(payload, true)}`;
    const response = await $api.put(url, null, 'The Tag permissions were successfully updated.');
    return response && response.status === 200;
  }

  public static async removeUserPermissionsForTag($api: any, $service: any, userId: string, tagId: number): Promise<boolean> {
    const payload = {
      elementId: userId,
      id: TypeHelper.isString(tagId) ? parseInt(tagId as any, 10) : tagId,
      resultsPermissions: null,
      editPermissions: null,
    };

    const url = `${$service.SearchController_Api_Url}Permission/UpdateTagPermissionsByUser?${PermissionManager.buildRequestParams(payload, true)}`;
    const response = await $api.put(url, null, 'The Tag permissions were successfully removed.');
    return response && response.status === 200;
  }

  public static async updateUserPermissionsForTarget($api: any, $service: any, userId: string, targetId: number, editPermissions: TargetEditPermissions, resultsPermissions: ResultsPermissions, suppressSuccessSnack: boolean = false): Promise<boolean> {
    // A null permission value means to remove the permission on the back end.  E.g. when a user wants to remove a permission on the UI we will pass null to this method.
    // The result permission should never be saved in the DB for the built in all targets id so it is always set to null.
    if (resultsPermissions === undefined || targetId === allTargetsEndpointId) {
      resultsPermissions = null; // e.g. clears the permission
    }
    // If the edit permission is undefined we force null to ensure the permission is removed from the DB.
    if (editPermissions === undefined) {
      editPermissions = null; // e.g. clears the permission
    }
    // The edit permission does not apply when the results permission is None so we force save None in all cases.
    if (resultsPermissions === ResultsPermissions.None) {
      editPermissions = TargetEditPermissions.None;
    }

    const payload = {
      elementId: userId,
      id: TypeHelper.isString(targetId) ? parseInt(targetId as any, 10) : targetId,
      resultsPermissions,
      editPermissions
    };

    const url = `${$service.SearchController_Api_Url}Permission/UpdateEndpointPermissionsByUser?${PermissionManager.buildRequestParams(payload, true)}`;
    const response = await $api.put(url, null, suppressSuccessSnack ? null : 'The Target permissions were successfully updated.');
    return response && response.status === 200;
  }

  public static async removeUserPermissionsForTarget($api: any, $service: any, userId: string, targetId: number, suppressSuccessSnack: boolean = false): Promise<boolean> {
    const payload = {
      elementId: userId,
      id: TypeHelper.isString(targetId) ? parseInt(targetId as any, 10) : targetId,
      resultsPermissions: null,
      editPermissions: null,
    };

    const url = `${$service.SearchController_Api_Url}Permission/UpdateEndpointPermissionsByUser?${PermissionManager.buildRequestParams(payload, true)}`;
    const response = await $api.put(url, null, suppressSuccessSnack ? null : 'The Target permissions were successfully removed.');
    return response && response.status === 200;
  }

  public static async updateUiElementRolePermission($api: any, $service: any, roleId: string, type: PermissionType, elementId: string, permission: BasicPermissions): Promise<boolean> {
    if (!PermissionManager.isValidUiElementPermissionType(type)) {
      return;
    }

    // A null permission is valid. E.g. it will delete the permission from the database
    if (permission === undefined) {
      permission = null;
    }

    const payload: ISaveUidPermissionPayload = {
      userOrRoleId: roleId,
      type,
      elementId,
      permission
    };

    const url = `${$service.SearchController_Api_Url}Permission/UpdateUidPermissionByRole?${PermissionManager.buildUidRequestParams(payload)}`;
    const response = await $api.put(url, null, null, 'The permissions update failed, please try again.');
    return response && response.status === 200;
  }

  public static async updateUiElementUserPermission($api: any, $service: any, userId: string, type: PermissionType, elementId: string, permission: BasicPermissions): Promise<boolean> {
    if (!PermissionManager.isValidUiElementPermissionType(type)) {
      return;
    }

    // A null permission is valid. E.g. it will delete the permission from the database
    if (permission === undefined) {
      permission = null;
    }

    const payload: ISaveUidPermissionPayload = {
      userOrRoleId: userId,
      type,
      elementId,
      permission
    };

    const url = `${$service.SearchController_Api_Url}Permission/UpdateUidPermissionByUser?${PermissionManager.buildUidRequestParams(payload, true)}`;
    const response = await $api.put(url, null, null, 'The permissions update failed, please try again.');
    return response && response.status === 200;
  }

  constructor() {
    this.rolePermissions = new RolePermissions();
    this.userPermissions = new UserPermissions();
    this.tagPermissionsForRoles = new RoleTagPermissions();
    this.targetPermissionsForRoles = new RoleTargetPermissions();
    this.tagPermissionsForUsers = new UserTagPermissions();
    this.targetPermissionsForUsers = new UserTargetPermissions();
    this.roleUiElementPermissions = new UiElementPermissions();
    this.userUiElementPermissions = new UiElementPermissions();
  }

  public async loadRolePermissions($api: any, $service: any, roleId: string) {
    const url = `${$service.SearchController_Api_Url}Permission/LoadPermissionsByRole?roleId=${roleId}`;
    const response = await $api.get(url, null, 'The role permissions failed to load. Please refresh the page to try again.');
    this.rolePermissions = new RolePermissions(response ? response.data : null);
  }

  public async loadUserPermissions($api: any, $service: any, userId: string) {
    const url = `${$service.SearchController_Api_Url}Permission/LoadPermissionsByUser?userId=${userId}`;
    const response = await $api.get(url, null, 'The user permissions failed to load. Please refresh the page to try again.');
    this.userPermissions = new UserPermissions(response ? response.data : null);
  }

  public async loadTagRolePermissions($api: any, $service: any, tagId: number) {
    const url = `${$service.SearchController_Api_Url}Permission/LoadPermissionsByTag?tagId=${tagId}`;
    const response = await $api.get(url, null, 'The tag permissions failed to load.');
    this.tagPermissionsForRoles = new RoleTagPermissions(response ? response.data : null);
  }

  public async loadTargetRolePermissions($api: any, $service: any, targetId: number) {
    const url = `${$service.SearchController_Api_Url}Permission/LoadPermissionsByEndpoint?endpointId=${targetId}`;
    const response = await $api.get(url, null, 'The target permissions failed to load.');
    this.targetPermissionsForRoles = new RoleTargetPermissions(response ? response.data : null);
  }

  public async loadTagUserPermissions($api: any, $service: any, tagId: number) {
    const url = `${$service.SearchController_Api_Url}Permission/LoadUserPermissionsByTag?tagId=${tagId}`;
    const response = await $api.get(url, null, 'The tag permissions by user failed to load.');
    this.tagPermissionsForUsers = new UserTagPermissions(response ? response.data : null);
  }

  public async loadTargetUserPermissions($api: any, $service: any, targetId: number) {
    const url = `${$service.SearchController_Api_Url}Permission/LoadUserPermissionsByEndpoint?endpointId=${targetId}`;
    const response = await $api.get(url, null, 'The tag permissions by user failed to load.');
    this.targetPermissionsForUsers = new UserTargetPermissions(response ? response.data : null);
  }

  public async loadRoleUiElementPermissions($api: any, $service: any, roleId: string, type: PermissionType) {
    if (!PermissionManager.isValidUiElementPermissionType(type)) {
      return;
    }
    const url = `${$service.SearchController_Api_Url}Permission/LoadUidPermissionsByRole?roleId=${roleId}&type=${type}`;
    const response = await $api.get(url, null, `Role permissions failed to load for type '${PermissionType[type]}'.`);
    const permissions = (response && ArrayHelper.isArraySet(response.data) ? response.data : []) as Pair<string, BasicPermissions>[];
    if (type === PermissionType.Scan) {
      this.roleUiElementPermissions.scanPermissions = permissions;
    }
    else if (type === PermissionType.Playbook) {
      this.roleUiElementPermissions.playbookPermissions = permissions;
    }
    else if (type === PermissionType.Report) {
      this.roleUiElementPermissions.reportPermissions = permissions;
    }
  }

  public async loadUserUiElementPermissions($api: any, $service: any, userId: string, type: PermissionType) {
    if (!PermissionManager.isValidUiElementPermissionType(type)) {
      return;
    }
    const url = `${$service.SearchController_Api_Url}Permission/LoadUidPermissionsByUser?userId=${userId}&type=${type}`;
    const response = await $api.get(url, null, `User permissions failed to load for type '${PermissionType[type]}'.`);
    const permissions = (response && ArrayHelper.isArraySet(response.data) ? response.data : []) as Pair<string, BasicPermissions>[];
    if (type === PermissionType.Scan) {
      this.userUiElementPermissions.scanPermissions = permissions;
    }
    else if (type === PermissionType.Playbook) {
      this.userUiElementPermissions.playbookPermissions = permissions;
    }
    else if (type === PermissionType.Report) {
      this.userUiElementPermissions.reportPermissions = permissions;
    }
  }

  public async loadUiElementPermissionsInfo($api: any, $service: any, type: PermissionType, userId: string, roleId: string): Promise<UiElementPermissionsInfo> {
    const info = new UiElementPermissionsInfo();

    if (!PermissionManager.isValidUiElementPermissionType(type)) {
      return info;
    }

    // UI does not allow Edit alone so if any permission from the api = Edit then we default to View+Edit for proper display (e.g. Manage).
    // eslint-disable-next-line
    const checkPermission = (value: BasicPermissions): BasicPermissions => value === BasicPermissions.Edit ? BasicPermissions.View | BasicPermissions.Edit : value;

    const loadMap = (uiPermissions: UiElementPermissions, map: any) => {
      //const permissions = type === PermissionType.Scan ? uiPermissions.scanPermissions : type === PermissionType.Playbook ? uiPermissions.playbookPermissions : [];
      let permissions = [];
      switch (type) {
        case PermissionType.Scan:
          permissions = uiPermissions.scanPermissions;
          break;
        case PermissionType.Playbook:
          permissions = uiPermissions.playbookPermissions;
          break;
        case PermissionType.Report:
          permissions = uiPermissions.reportPermissions;
          break;
        default:
          break;
      }
      permissions.forEach(p => map[p.first] = checkPermission(p.second));
    };

    if (!StringHelper.isNullOrEmpty(userId)) {
      await this.loadUserUiElementPermissions($api, $service, userId, type);
      loadMap(this.userUiElementPermissions, info.userPermissionsMap);
    }

    if (!StringHelper.isNullOrEmpty(roleId)) {
      await this.loadRoleUiElementPermissions($api, $service, roleId, type);
      loadMap(this.roleUiElementPermissions, info.rolePermissionsMap);
    }

    return info;
  }

  //eslint-disable-next-line
  public async loadPermissionsForElement($api: any, $service: any, elementId: string, type: PermissionType, loadUsers: boolean, loadRoles: boolean): Promise<UiElementPermissionsInfo> {
    const info = new UiElementPermissionsInfo();

    if (!PermissionManager.isValidUiElementPermissionType(type)) {
      return info;
    }

    if (loadUsers) {
      const response = await $api.get(`${$service.SearchController_Api_Url}Permission/LoadUserUidPermissionsByElement?elementId=${elementId}&type=${type}`);
      if (response && ArrayHelper.isArraySet(response.data)) {
        (response.data as Pair<string, number>[]).forEach(p => info.userPermissionsMap[p.first] = p.second);
      }
    }
    if (loadRoles) {
      const response = await $api.get(`${$service.SearchController_Api_Url}Permission/LoadRoleUidPermissionsByElement?elementId=${elementId}&type=${type}`);
      if (response && ArrayHelper.isArraySet(response.data)) {
        (response.data as Pair<string, number>[]).forEach(p => info.rolePermissionsMap[p.first] = p.second);
      }
    }
    return info;
  }

  public getRolePermissionsForTag(tagId: number, isChildTag: boolean = false): TagPermissionInfo {
    // null will display Inherited on the UI. e.g. child tags inherit their permission from the parent tag when no permission is explicitly set excluding the all targets tag
    const defaultEditPermission = isChildTag ? null : TagEditPermissions.None;
    const defaultResultsPermission = null; // result permission can be inherited for all tags, parent or child

    if (TypeHelper.isNull(tagId)) {
      return new TagPermissionInfo(defaultEditPermission, defaultResultsPermission);
    }

    const currentEditPermission = this.rolePermissions.tagEditPermissions.find(t => t.first === tagId);
    const currentResultPermission = this.rolePermissions.tagResultsPermissions.find(t => t.first === tagId);

    return new TagPermissionInfo(
      currentEditPermission ? currentEditPermission.second : defaultEditPermission,
      currentResultPermission ? currentResultPermission.second : defaultResultsPermission
    );
  }

  public getTagPermissionsForRole(roleId: string, isChildTag: boolean = false) {
    // expects loadTagRolePermissions to be called for a tag prior to using this method
    // null will display Inherited on the UI. e.g. child tags inherit their permission from the parent tag when no permission is explicitly set
    const defaultEditPermission = isChildTag ? null : TagEditPermissions.None;
    const defaultResultsPermission = null; // result permission can be inherited for all tags, parent or child

    if (TypeHelper.isNull(roleId)) {
      return new TagPermissionInfo(defaultEditPermission, defaultResultsPermission);
    }

    const currentPermission = this.tagPermissionsForRoles.permissions.find(p => p.roleId === roleId);

    return new TagPermissionInfo(
      currentPermission ? currentPermission.editPermission : defaultEditPermission,
      currentPermission ? currentPermission.resultPermission : defaultResultsPermission
    );
  }

  public getUserPermissionsForTag(tagId: number): UserTagPermissionInfo {
    if (TypeHelper.isNull(tagId)) {
      return new UserTagPermissionInfo(null, null);
    }

    const currentEditPermission: IUserPermissionServerItem = this.userPermissions.permissions.find(t => t.elementId === tagId && t.type === PermissionType.TagEdit);
    const currentResultPermission: IUserPermissionServerItem = this.userPermissions.permissions.find(t => t.elementId === tagId && t.type === PermissionType.TagResult);

    return new UserTagPermissionInfo(currentEditPermission, currentResultPermission);
  }

  public getTagPermissionsForUser(userId: string) : UserTagPermissionInfo {
    const info = new UserTagPermissionInfo(null, null);
    if (TypeHelper.isNull(userId)) {
      return info;
    }

    const currentPermission = this.tagPermissionsForUsers.permissions.find(p => p.userId === userId);
    if (!TypeHelper.isNull(currentPermission)) {
      info.editPermission = currentPermission.editPermission;
      info.resultPermission = currentPermission.resultPermission;
      if (currentPermission.isUserSet) {
        info.editSetByUser = true;
        info.resultSetByUser = true;
        info.userEditPermission = info.editPermission;
        info.userResultPermission = info.resultPermission;
      }
    }
    return info;
  }

  public getRolePermissionsForTarget(targetId: number): TargetPermissionInfo {
    if (TypeHelper.isNull(targetId)) {
      return new TargetPermissionInfo(null, null);
    }

    // Note: using == due to APIS returning target id's as strings in the grid data.
    // eslint-disable-next-line
    const currentEditPermission = this.rolePermissions.targetEditPermissions.find(t => t.first == targetId);
    // eslint-disable-next-line
    const currentResultPermission = this.rolePermissions.targetResultsPermissions.find(t => t.first == targetId);

    return new TargetPermissionInfo(
      currentEditPermission ? currentEditPermission.second : null,
      currentResultPermission ? currentResultPermission.second : null
    );
  }

  public getTargetPermissionsForRole(roleId: string) {
    // expects loadTargetRolePermissions to be called for a target prior to using this method
    if (TypeHelper.isNull(roleId)) {
      return new TargetPermissionInfo(null, null);
    }

    const currentPermission = this.targetPermissionsForRoles.permissions.find(p => p.roleId === roleId);

    return new TargetPermissionInfo(
      currentPermission ? currentPermission.editPermission : null,
      currentPermission ? currentPermission.resultPermission : null
    );
  }

  public getUserPermissionsForTarget(targetId: number): UserTargetPermissionInfo {
    if (TypeHelper.isNull(targetId)) {
      return new UserTargetPermissionInfo(null, null);
    }

    // Note: using == due to APIS returning target id's as strings in the grid data.
    // eslint-disable-next-line
    const currentEditPermission: IUserPermissionServerItem = this.userPermissions.permissions.find(t => t.elementId == targetId && t.type === PermissionType.EndpointEdit);
    // eslint-disable-next-line
    const currentResultPermission: IUserPermissionServerItem = this.userPermissions.permissions.find(t => t.elementId == targetId && t.type === PermissionType.EndpointResult);

    return new UserTargetPermissionInfo(currentEditPermission, currentResultPermission);
  }

  public getTargetPermissionsForUser(userId: string): UserTargetPermissionInfo {
    const info = new UserTargetPermissionInfo(null, null);
    if (TypeHelper.isNull(userId)) {
      return info;
    }

    const currentPermission = this.targetPermissionsForUsers.permissions.find(p => p.userId === userId);
    if (!TypeHelper.isNull(currentPermission)) {
      info.editPermission = currentPermission.editPermission;
      info.resultPermission = currentPermission.resultPermission;
      if (currentPermission.isUserSet) {
        info.editSetByUser = true;
        info.resultSetByUser = true;
        info.userEditPermission = info.editPermission;
        info.userResultPermission = info.resultPermission;
      }
    }
    return info;
  }

  public getRolePermissionsForAllTargetsTag(isUserPermissions: boolean = false): AllTargetPermissionInfo {
    if (isUserPermissions) {
      return new AllTargetUserPermissionInfo(this.getUserPermissionsForTarget(allTargetsEndpointId), this.getUserPermissionsForTag(allTargetsTagId));
    }
    return new AllTargetPermissionInfo(this.getRolePermissionsForTarget(allTargetsEndpointId).editPermission, this.getRolePermissionsForTag(allTargetsTagId).resultPermission);
  }
}
