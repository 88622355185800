import { VueConstructor } from 'vue';
import store from '@/store';
import claims from '@/helpers/ClaimsHelper';
import TypeHelper from '@/helpers/TypeHelper';
import StringHelper from '@/helpers/StringHelper';
import ArrayHelper from '@/helpers/ArrayHelper'

/**
 * @description check if the user has permission per given claim
 * @param {string|string[]} claims claim name(s) to check against
 * @param {boolean} requireAllClaims require all claims in a claims array to be in the permission list. Ignored when claims is a string.
 * @returns {boolean} default returns true
 */
export function checkPermissions(claims: string | string[], requireAllClaims: boolean = false): boolean {
  if (TypeHelper.isString(claims) && !StringHelper.isNullOrEmpty((claims as string))) {
    return store.getters['session/getPermissions'].includes(claims);
  }
  if (TypeHelper.isArray(claims) && ArrayHelper.isArraySet((claims as string[]))) {
    if (requireAllClaims) {
      return (claims as string[]).every(claim => store.getters['session/getPermissions'].includes(claim));
    }
    return (claims as string[]).some(claim => store.getters['session/getPermissions'].includes(claim));
  }
  return true;
}

export default {
  install(Vue: VueConstructor) {
    Vue.prototype.$permissions = checkPermissions;
    Vue.prototype.$claims = claims;
  }
};
