export default abstract class TypeHelper {
  public static get minInt(): number { return -2147483648; }

  public static get emptyGuid(): string { return '00000000-0000-0000-0000-000000000000'; }

  public static getGuid(): string {
    let d = new Date().getTime();
    if (window.performance && typeof window.performance.now === 'function') {
      d += performance.now(); //use high-precision timer if available
    }
    const guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
      // eslint-disable-next-line
      c => {
        // eslint-disable-next-line
        const r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        // eslint-disable-next-line
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
    return guid;
  }

  public static isArray(obj: any) {
    if (TypeHelper.isNull(obj)) {
      return false;
    }
    return Object.prototype.toString.call(obj) === '[object Array]';
  }

  public static isString(obj: any) {
    if (TypeHelper.isNull(obj)) {
      return false;
    }
    // literal or an object
    return typeof obj === 'string' || obj instanceof String;
  }

  public static isNumber(obj: any) {
    if (TypeHelper.isNull(obj)) {
      return false;
    }
    return typeof obj === 'number' || obj instanceof Number;
  }

  public static isObject(object) {
    return object !== null && typeof object === 'object';
  }

  public static objectsEqual(object1: any, object2: any) {
    object1 = { ...object1 };
    object2 = { ...object2 };

    object1 = object1[0] ? object1[0] : object1;
    object2 = object2[0] ? object2[0] : object2;

    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return false;
    }
    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = this.isObject(val1) && this.isObject(val2);
      if ((areObjects && !this.objectsEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
        return false;
      }
    }
    return true;
  }

  public static isNull(value: any): boolean {
    return value === null || value === undefined;
  }

  // JavaScript will cast to 32-bit when doing bitwise AND. Taken from here: https://stackoverflow.com/a/43666199/209659
  public static and(v1, v2): number {
    const hi = 0x80000000;
    const low = 0x7fffffff;
    // eslint-disable-next-line
    const hi1 = ~~(v1 / hi);
    // eslint-disable-next-line
    const hi2 = ~~(v2 / hi);
    // eslint-disable-next-line
    const low1 = v1 & low;
    // eslint-disable-next-line
    const low2 = v2 & low;
    // eslint-disable-next-line
    const h = hi1 & hi2;
    // eslint-disable-next-line
    const l = low1 & low2;
    return h * hi + l;
  }

  public static fixDate(date: Date): Date {
    return TypeHelper.isNull(date) ? null : TypeHelper.isString(date) ? new Date(<any>date) : date;
  }

  public static datesEqual(date1: Date, date2: Date) {
    const d1 = TypeHelper.fixDate(date1);
    const d2 = TypeHelper.fixDate(date2);

    // === or !== operators do not work on dates. you must use getTime() then compare
    const c1 = TypeHelper.isNull(d1) ? null : d1.getTime();
    const c2 = TypeHelper.isNull(d2) ? null : d2.getTime();

    return c1 === c2;
  }
}
