import agentsRoutes from './_agents'
import analyticsRoutes from './_analytics'
import activityRoutes from './_activity'
import complainceRoutes from './_compliance'
import dashboardRoutes from './_dashboard'
import miscRoutes from './_misc'
import oidcRoutes from './_oidc'
import playbooksRoutes from './_playbooks'
import reportsRoutes from './_reports'
import scansRoutes from './_scans'
import settingsRoutes from './_settings'
import dataAssetInventoryRoutes from './_inventory'
import accessDenied from './_40x'
import depreciatedRoutes from './_depreciatedRoutes'
import notificationRoutes from './_notifications'

export default [
  {
    path: '*',
    redirect: '/dashboard'
  },
  {
    path: '/',
    redirect: '/dashboard'
  },
  ...accessDenied,
  ...activityRoutes,
  ...agentsRoutes,
  ...analyticsRoutes,
  ...complainceRoutes,
  ...dashboardRoutes,
  ...oidcRoutes,
  ...playbooksRoutes,
  ...reportsRoutes,
  ...scansRoutes,
  ...settingsRoutes,
  ...miscRoutes,
  ...notificationRoutes,
  ...dataAssetInventoryRoutes,
  ...depreciatedRoutes
]
