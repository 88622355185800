import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import AlpineIcon from '@/components/ui/AlpineIcon.vue';
import alpineIcons from '@/plugins/vuetify/alpineIcons.json';
import vuetifyIcons from '@/plugins/vuetify/vuetifyIcons.json';

Vue.use(Vuetify);

const buildIconList = (arr: any[], prefix = false) => arr.reduce((result, curr) => {
  const icon = prefix ? `alpine-${curr}` : curr[0];
  result[icon] = {
    component: AlpineIcon,
    props: {
      name: prefix ? curr : curr[1] || curr[0],
    },
  };
  return result;
}, {});

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#0069aa',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#B50D4D',
        info: '#2196F3',
        success: '#5BA852',
        warning: '#E8AA32',
        mauve: '#A975A0',
        teal: '#81B8B2',
        white: '#FFFFFF'
      },
    },
  },
  icons: {
    values: {
      ...buildIconList(vuetifyIcons),
      ...buildIconList(alpineIcons, true),
    },
  },
});
