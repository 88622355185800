export default [
  {
    name: 'business-processes',
    path: '/data-asset-inventory/business-processes',
    component: () => import('@/pages/data-asset-inventory/business-processes/PageBusinessProcessList.vue'),
  },
  {
    name: 'business-process-wizard',
    path: '/data-asset-inventory/business-processes/wizard',
    component: () => import('@/pages/data-asset-inventory/business-processes/PageBusinessProcessWizard.vue'),
    meta: {
      layout: 'fullscreen',
    },
  },
  {
    name: 'business-process-manage',
    path: '/data-asset-inventory/business-processes/wizard/:id',
    component: () => import('@/pages/data-asset-inventory/business-processes/PageBusinessProcessWizard.vue'),
    meta: {
      layout: 'fullscreen'
    }
  },
  {
    name: 'article-30-report',
    path: '/data-asset-inventory/business-processes/article-30-report/:id',
    component: () => import('@/pages/data-asset-inventory/business-processes/Article30Report.vue'),
  },
  {
    name: 'data-flow-map',
    path: '/data-asset-inventory/business-processes/data-flow-map/:id',
    component: () => import('@/pages/data-asset-inventory/business-processes/data-flow-map/PageDataFlowMap.vue'),
    meta: {
      layout: 'fullscreen'
    }
  },
  // {
  //   name: 'data-assets',
  //   path: '/data-asset-inventory/data-assets',
  //   component: () => import('@/pages/data-asset-inventory/data-assets/PageDataAssetsList.vue'),
  // },
  {
    name: 'data-target-details',
    path: '/data-asset-inventory/data-assets-targets/target-details/:id',
    props: true,
    component: () => import('@/pages/data-asset-inventory/data-assets/PageTargetDetails.vue')
  },
  {
    name: 'data-asset-details',
    path: '/data-asset-inventory/data-assets-targets/asset-details/:id',
    props: true,
    component: () => import('@/pages/data-asset-inventory/data-assets/PageDataAssetDetails.vue')
  },
  {
    name: 'data-assets-targets',
    path: '/data-asset-inventory/data-assets-targets',
    component: () => import('@/pages/data-asset-inventory/data-assets/PageDataAssetsAndTargets.vue')
  },
  {
    name: 'data-asset-wizard',
    path: '/data-asset-inventory/data-assets/wizard',
    component: () => import('@/pages/data-asset-inventory/data-assets/PageDataAssetWizard.vue'),
    meta: {
      layout: 'fullscreen',
    }
  },
  {
    name: 'data-asset-manage',
    path: '/data-asset-inventory/data-assets/wizard/:id',
    component: () => import('@/pages/data-asset-inventory/data-assets/PageDataAssetWizard.vue'),
    meta: {
      layout: 'fullscreen',
    }
  }
];
