export default [
    {
        name: 'activity-dashboard-depreciated',
        path: '/activity/dashboard',
        redirect: { name: 'activity-dashboard' },
    },
    {
        name: 'query-list-depreciated',
        path: '/activity/queries',
        redirect: { name: 'query-list' },
    },
    {
        name: 'incident-definitions-list-depreciated',
        path: '/activity/incidents/definitions',
        redirect: { name: 'incident-definitions-list' },
    },
    {
        name: 'current-incidents-depreciated',
        path: '/activity/incidents/current',
        redirect: { name: 'current-incidents' },
    },
    {
        name: 'sensitive-data-watcher-list-depreciated',
        path: '/activity/watchers',
        redirect: { name: 'sensitive-data-watcher-list' },
    },
    {
        name: 'agents-tags-depreciated',
        path: '/agents/tags',
        redirect: { name: 'agents-tags' },
    },
    {
        name: 'compliance-dashboard-depreciated',
        path: '/compliance/dashboard',
        redirect: { name: 'compliance-dashboard' },
    },
    {
        name: 'compliance-all-requests-depreciated',
        path: '/compliance/all-requests',
        redirect: { name: 'compliance-all-requests' },
    },
    {
        name: 'compliance-all-reports-depreciated',
        path: '/compliance/all-reports',
        redirect: { name: 'compliance-all-reports' },
    },
    {
        name: 'playbooks-depreciated',
        path: '/playbooks',
        redirect: { name: 'playbooks' },
    },
    {
        name: 'script-repository-depreciated',
        path: '/playbooks/scripts',
        redirect: { name: 'custom-scripts' },
    },
    {
      name: 'reports-landing-depreciated',
      path: '/reports/reports',
      redirect: { name: 'reports-landing', params: { showDownloadsTab: 'true' } }
    },
    {
        name: 'reports-audit-depreciated',
        path: '/reports/audit',
        redirect: { name: 'reports-audit' }
    },
    {
        name: 'reports-platform-logs-depreciated',
        path: '/reports/platform-logs',
        redirect: { name: 'reports-platform-logs' },
    },
    {
        name: 'reports-gathered-data-depreciated',
        path: '/reports/gathered-data',
        redirect: { name: 'reports-gathered-data' },
    },
    {
        name: 'settings-classifications-depreciated',
        path: '/settings/classifications',
        redirect: { name: 'settings-classifications' },
    },
    {
        name: 'settings-data-types-depreciated',
        path: '/settings/data-types',
        redirect: { name: 'settings-data-types' },
    },
    {
        name: 'global-settings-depreciated',
        path: '/settings/global-settings',
        redirect: { name: 'global-settings' },
    },
    {
        name: 'compliance-settings-depreciated',
        path: '/settings/compliance-settings',
        redirect: { name: 'compliance-settings' },
    },
]
