export default [
  {
    name: 'access-denied',
    path: '/401',
    component: () => import('@/pages/401.vue'),
    meta: {
      isPublic: true,
      layout: 'fullscreen'
    },
  }
];
