import EnumUtility from '@/enums/EnumUtility';

export enum EnterpriseAgentType {
  Sdm,
  Discovery,
  Search,
  Remediation,
  Agent
}

export enum AgentStatus {
  Unknown = 0,
  Idle = 1,
  Searching = 2,
  Discovering = 4,
  Error = 8,
  Offline = 16
}

export abstract class AgentStatusUtil {
  public static toDisplay(type: AgentStatus): string {
    const getString = (t: AgentStatus): string => {
      if (t === undefined) {
        t = 0;
      }

      switch (t) {
        case AgentStatus.Idle:
          return 'Agent Ready';
        case AgentStatus.Searching:
          return 'Scanning';
        case AgentStatus.Discovering:
          return 'Discovery';
        default:
          return AgentStatus[t];
      }
    };

    return EnumUtility.flagsEnumToDisplay(type, AgentStatus, getString, null, false);
  }
}

// EndpointStatus is used for sdm agents. E.g. agents before Greyhound project and new AgentStatus enum above
export enum EndpointStatus {
    Offline,
    Scanning,
    Idle,
    Monitoring
}

export enum EndpointStates {
  UpToDate = 'Up To Date',
  Processing ='Processing',
  PendingUpdate ='Pending Update',
  PendingConfirmation ='Pending Confirmation',
  Failure ='Failure',
  Removed ='Removed'
}

export enum State {
  UpToDate,
  Processing,
  PendingUpdate,
  PendingConfirmation,
  Failure,
  Removed
}

export enum Monitoring {
    No,
    Yes
}

export enum Role {
    NoAccess = 0,
    ReadAccess = 1,
    WriteAccess = 2,
}

export enum RoleName {
    ProductOwner,
    Admin,
    DataPrivacyUser,
    GeneralUser
}

export enum Securables {
    OrganizationList = 'OrganizationList',
    AddOrganization = 'AddOrganization',
    OrganizationDetail = 'OrganizationDetail',
    UserList = 'UserList',
    UserDetail = 'UserDetail',
    DeleteUser = 'DeleteUser',
    AgentConfiguration = 'AgentConfiguration',
    ListAgentDetails = 'ListAgentDetails',
    AssignPolicy = 'AssignPolicy',
    Incident = 'Incident',
    IncidentDefinition = 'IncidentDefinition',
    CustomScript = 'CustomScript',
    PolicyConfig = 'PolicyConfig',
    UserProfile = 'UserProfile',
    RemoveAgent = 'RemoveAgent',
    ResetPswdEmail = 'ResetPwdEmail',
    ImportUser = 'ImportUser',
    AddRole = 'AddRole',
    CookiePolicy = 'CookiePolicy',
    AddGroupWrite = 'AddGroupWrite',
    SidList = 'SidList',
    QueryList = 'QueryList',
    ManageQuery = 'ManageQuery',
    QueryCategory = 'QueryCategory',
    IncidentDeployment = 'IncidentDeployment',
    IncidentDefinitionScript = 'IncidentDefinitionScript',
    ClientIncidentDefinition = 'ClientIncidentDefinition',
    IncidentDetectionModel = 'IncidentDetectionModel',
    UpdateDefinitionStatus = 'UpdateDefinitionStatus',
    UpdateDefinitionPlaybook = 'UpdateDefinitionPlaybook',
}

export enum SubscriptionLevel {
    Paid,
    POC,
    Partner,
}
