import TypeHelper from '@/helpers/TypeHelper';
import ArrayHelper from '@/helpers/ArrayHelper'

export default abstract class StringHelper {
  public static isNullOrWhiteSpace(value: string): boolean {
    return !value || value.trim().length === 0;
  }

  public static isNullOrEmpty(value: string): boolean {
    return !value || value.length === 0;
  }

  public static isUuid(value: string): boolean {
    const mask = /[\w]{8}-[\w]{4}-[a-z\d]{4}-[a-z\d]{4}-[a-z\d]{12}$/;
    return mask.test(value);
  }

  public static containsGuid(value: string): boolean {
    const mask = /[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/;
    return mask.test(value);
  }

  public static removeLastSpaceAndNumbers(value: string): string {
    try {
      return value.replace(/\s[^\sa-zA-Z]+$/, '')
    } catch (error) {
      return value
    }
  }

  public static removeLastUnderscoreAndNumbers(value: any): string {
    if (StringHelper.isNullOrWhiteSpace(value)) return '';
    return value.replace(/_\d+$/, '')
  }

  public static getNumbersFromEndOfString(value: any): string {
    if (StringHelper.isNullOrWhiteSpace(value)) return ''
    return value.match(/\d+$/, '')
  }

  public static getFileNameFromPath(value: string): string {
    try {
      return value.match(/[-_\w'\s]+[.][\w]+$/i)[0];
    } catch (error) {
      return value;
    }
  }

  public static removeFileNameFromPath(value: string): string {
    return value.replace(/[^/]*$'/, '');
  }

  public static lowerCase(value: string, trim: boolean = true): string {
    if (TypeHelper.isNull(value)) {
      return '';
    }
    return trim ? value.toLowerCase().trim() : value.toLowerCase();
  }

  public static stringsEqualIgnoreCase(str1: string, str2: string, trim: boolean = true): boolean {
    return StringHelper.lowerCase(str1, trim) === StringHelper.lowerCase(str2, trim);
  }

  public static dataTypeUpperCase(str: string): string{
    if (StringHelper.isNullOrWhiteSpace(str)) {
      return str;
    }
    const smartField = ['nhs', 'tfn', 'nino', 'sin'];
    const parts: string[] = [];
    str.split(' ').forEach((part: string) => {
      if (!StringHelper.isNullOrWhiteSpace(part) && smartField.includes(part.toLowerCase())) {
        parts.push(part.toUpperCase());
      }
      else {
        parts.push(part);
      }
    });
    return parts.join(' ');
  }

  public static formatToShow(str: string) {
    if (TypeHelper.isNull(str)) {
      return '';
    }
    const pstring = str.split('_');
    if (pstring.length > 2){
       str = pstring.slice(0, 2).join('_');
    }
    return str;
  }

  public static humanizeSnakeCase(str: string, isPIIDataType: boolean = true) {
    if (TypeHelper.isNull(str)) {
      return '';
    }
    str += ''
    str = str.split('_').map(frag => frag.charAt(0).toUpperCase() + frag.slice(1)).join(' ');
    return isPIIDataType ? this.dataTypeUpperCase(str) : str;
  }

  /**
  * @description splits a string on new lines returning an array of all non-empty strings
  * @param {string} value string to split.
  * @param {Function} itemProcessor optional. When provided, each non-empty string will be processed. Processed string will be included in final array
  */
  public static splitOnNewLine(value: string, itemProcessor: (item: string) => string = null): string[] {
    if (StringHelper.isNullOrWhiteSpace(value)) {
      return [];
    }

    const parts = value.split('\n');
    const finalParts: string[] = [];
    parts.forEach((part) => {
      if (!StringHelper.isNullOrWhiteSpace(part)) {
        part = part.trim();
        if (itemProcessor) {
          part = itemProcessor(part);
          if (StringHelper.isNullOrWhiteSpace(part)) {
            return; // E.g. continue the loop
          }
        }
        finalParts.push(part);
      }
    });
    return finalParts;
  }

  public static trimToLength(data: string, maxLen: number, emptyValueResult: string = '', useTrailingEllipses: boolean = false): string {
    if (!data) {
      return emptyValueResult;
    }

    if (!maxLen || maxLen < 1) {
      return data;
    }

    if (StringHelper.isNullOrWhiteSpace(data) || !maxLen) {
      return data;
    }

    if (data.length <= maxLen) {
      return data;
    }

    return `${data.substring(0, maxLen)}${(useTrailingEllipses ? '...' : '')}`;
  }

  public static toKebabCasing = (str: string): string => {
    if (StringHelper.isNullOrEmpty(str)) {
      return str;
    }
    return str.replace(/\s+/g, '-').toLowerCase();
  };

  public static toSnakeCasing = (str: string): string => {
    if (StringHelper.isNullOrEmpty(str)) {
      return str;
    }
    return str.replace(/\s+/g, '_').toLowerCase();
  };

  /**
  * @description normalizes a string by removing its separators, then returns an array of said string in different formats
  * @param {string} matchName name of string (e.g. credit_card, phone_number) used to correct formatting indicators
  * @param {string} matchValue value of string that is processed to return an array of its different formats
  */
  public static getFormattedPii(matchName: string, matchValue: string): Array<string> {
    const key = {
      credit_card_key: {
          16: [0, 4, 8, 12],
          19: [0, 4, 8, 12, 16],
          13: [0, 4, 8],
          15: [0, 4, 9],
          14: [0, 4, 10]
      },
      united_ssn_key: { 9: [0, 3, 5] },
      phone_number_key: { 10: [0, 3, 6], 7: [0, 3] }
    };
    const separators = [' ', '.', '/', '-'];
    const match = matchValue.replace(/ |\.|\/|-/g, '');
    const matches = [match];
    const splitMatch = [];

    const keyArr = key[`${matchName}_key`][match.length];
    if (!keyArr) {
      return matches;
    }
    keyArr.forEach((value:number, i: number) => {
      splitMatch.push(match.slice(value, keyArr[i + 1]));
    })
    for (const separator of separators) {
      matches.push(splitMatch.join(separator));
    }
    return matches;
  }

  public static dateToFormattedStrings(date: string): Array<string> {
    const date_arr = date.split('-');
    const formatted_dates = [];
    const separators = [' ', '.', '/', '-', ''];
    const permutated_dates = ArrayHelper.permutate(date_arr);
    for (const separator of separators) {
      for (const date of permutated_dates) {
        formatted_dates.push(date.join(separator));
      }
    }
    return formatted_dates;
  }

  public static trim(str: string): string {
    return StringHelper.isNullOrEmpty(str) ? str : str.trim();
  }

  public static upperCaseFirstChar(str: string): string {
    if (StringHelper.isNullOrEmpty(str)){
     return str;
    }
    if (str.length === 1){
       return str.toUpperCase();
    }
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  public static toTitleCasing(str: string): string {
    let formattedString = this.upperCaseFirstChar(str);
    formattedString = formattedString.replace(/([A-Z])/g, ' $1');
    return formattedString;
  }
}
