













import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'AlpineLayoutDefault',
  components: {
    AlpineNavBar: () => import('@/components/navbar/AlpineNavBar.vue'),
  },
  data() {
    return {
      alpineDefaultLayoutMainId: 'alpine-layout-default-main'
    };
  },
  computed: {
    isFullScreen(): boolean {
      return (
        this.$route
        && this.$route.meta
        && this.$route.meta.layout === 'fullscreen'
      );
    },
    getLevel(): Number {
      return this.$route.path.split('/').length - 1 || 1;
    },
    //...mapGetters('session', ['getUserRole', 'userId', 'tokenObject', 'orgId']),
    ...mapGetters('oidc', ['oidcIsAuthenticated']),
  },
  watch: {
    $route() {
      // try/catch is just to be overly cautious
      try {
        const ele = document.getElementById(this.alpineDefaultLayoutMainId);
        if (ele) {
          ele.scrollTo(0, 0);
        }
      }
      catch { }
    }
  },
  // Pendo and userlanes were disabled per AL-10500.  If either is added back then the script(s) in public/index.html need to be uncommented
  //watch: {
  //  oidcIsAuthenticated(val) {
  //    if (val) {
  //      this.initializePlugins();
  //    }
  //  },
  //},
  //mounted() {
  //  this.initializePlugins();
  //},
  //methods: {
  //  initializePlugins() {
  //    this.initializePendo();
  //    this.initializeUserLanes();
  //  },
  //  initializePendo() {
  //    if (!this.oidcIsAuthenticated) {
  //      return;
  //    }

  //    if (!pendo) {
  //      return;
  //    }

  //    try {
  //      const userId: string = this.userId;
  //      const accountId = this.orgId;
  //      if (!userId || !accountId) {
  //        // require user and account id; otherwise the data is accumulated as anonymous in pendo and is not useful
  //        return;
  //      }
  //      // optional data
  //      const token = this.tokenObject;
  //      const userRole = token && token.role ? token.role : this.getUserRole;
  //      pendo.initialize({
  //        visitor: {
  //          id: userId,
  //          role: userRole,
  //        },
  //        account: {
  //          id: accountId,
  //        },
  //      });
  //    } catch (err) {
  //      // do nothing.  pendo is not required for any application functionality
  //    }
  //  },
  //  initializeUserLanes() {
  //    if (!this.oidcIsAuthenticated) {
  //      return;
  //    }

  //    if (!userlane) {
  //      return;
  //    }

  //    try {
  //      const userId: string = this.userId;
  //      if (!userId) {
  //        //return;
  //      }
  //      // In flagship user lanes had issues with the dashes in the Guids so they are removed for DPM as well.
  //      userlane('identify', userId.replace('-', ''));
  //      userlane('init', 'x6dem');
  //    } catch (err) {
  //      // do nothing. user lanes is not required for any application functionality
  //    }
  //  },
  //},
});
