import claims from '@/helpers/ClaimsHelper';

export default [
  {
    name: 'compliance-dashboard',
    path: '/finder/dashboard',
    component: () => import('@/pages/compliance/dashboard/ComplianceDashboardComponent.vue'),
    meta: {
      claims: claims.ComplianceDashboard
    }
  },
  {
    name: 'compliance-all-requests',
    path: '/finder/subject-requests',
    component: () => import('@/pages/compliance/ComplianceAllRequestsComponent.vue'),
    meta: {
      claims: claims.ComplianceAllRequests
    }
  },
  {
    name: 'compliance-edit-subject-request',
    path: '/compliance/subject-request',
    component: () => import('@/pages/compliance/ComplianceNewRequestComponent.vue'),
    meta: {
      claims: [claims.ComplianceNewSubjectRequest, claims.ComplianceViewRequestDetails]
    }
  },
  {
    name: 'compliance-view-subject-request',
    path: '/compliance/subject-request',
    component: () => import('@/pages/compliance/ComplianceNewRequestComponent.vue'),
    meta: {
      claims: [claims.ComplianceNewSubjectRequest, claims.ComplianceViewRequestDetails]
    }
  },
  {
    name: 'compliance-new-subject-request',
    path: '/compliance/subject-request',
    component: () => import('@/pages/compliance/ComplianceNewRequestComponent.vue'),
    meta: {
      claims: [claims.ComplianceNewSubjectRequest, claims.ComplianceViewRequestDetails]
    }
  },
  {
    name: 'compliance-all-reports',
    path: '/finder/subject-reports',
    component: () => import('@/pages/compliance/ComplianceAllReportsComponent.vue'),
    meta: {
      claims: claims.ComplianceViewAllReports
    }
  },
  {
    name: 'compliance-erasure-checklist',
    path: '/compliance/erasure-checklist/:id',
    component: () => import('@/pages/compliance/ComplianceErasureChecklistComponent.vue'),
    meta: {
      claims: claims.ComplianceErasureChecklist,
      layout: 'no-sidebar'
    }
  },
  {
    name: 'compliance-match-results',
    path: '/compliance/match-results/:id/:subjectname',
    component: () => import('@/pages/compliance/ComplianceMatchResultsComponent.vue'),
    meta: {
      claims: claims.ComplianceViewSubjectResults,
      helpUrl: '/Content/Data Privacy Manager/Compliance/Compliance.htm'
    }
  },
  {
    name: 'compliance-file-content-review',
    path: '/compliance/file-content-review/:uuid',
    component: () => import('@/pages/compliance/ComplianceFileContentReviewComponent.vue'),
    meta: {
      claims: claims.ComplianceViewRequestDetails
    }
  },
  {
    name: 'compliance-data-map',
    path: '/compliance/data-map',
    component: () => import('@/pages/compliance/ComplianceDataMapComponent.vue')
  },
  {
    name: 'compliance-subject-report',
    path: '/compliance/subject-report/:reportId/:requestId',
    component: () => import('@/pages/compliance/ComplianceSubjectReportComponent.vue'),
    meta: {
      claims: claims.ComplianceViewAllReports, // claims.ComplianceViewReport
    }
  }
 ]
