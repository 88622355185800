export default [
  {
    name: 'health-check',
    path: '/healthcheck',
    component: () => import('@/pages/HealthCheck.vue'),
    meta: {
      isPublic: true
    }
  }
  // {
  //   path: 'PolicyConfiguration',
  //   component: () => import('@/pages/configuration/PolicyConfigurationComponent.vue')
  // },
  // {
  //   path: 'ViewEventDetails/:id/:incidentId',
  //   component: () => import('@/pages/incidents/ViewEventDetailsComponent.vue')
  // },
  // {
  //   path: 'agentversion',
  //   component: () => import('@/pages/agents/AgentVersionListComponent.vue')
  // },
  // {
  //   path: 'addagentversion',
  //   component: () => import('@/pages/agents/AddAgentVersionComponent.vue')
  // },
]
