import jwt_decode from 'jwt-decode';
import StringHelper from '@/helpers/StringHelper';
import RoleModel from '@/components/common/RoleModel';
import claims from '@/helpers/ClaimsHelper';

// Yes, the API actually returns string value = 'string' which is most likely due to invalid seed data during deployment.
const cleanApiString = (value: string) => (StringHelper.isNullOrWhiteSpace(value) || value.toLowerCase() === 'string' ? '' : value);

const joinName = (first: string, last: string): string => {
  const fullName = `${cleanApiString(first)} ${cleanApiString(last)}`.trim();
  return StringHelper.isNullOrWhiteSpace(fullName) ? null : fullName;
};

export default {
  tokenObject: (state, getters, rootState, rootGetters) => (rootGetters['oidc/oidcAccessToken'] ? jwt_decode(rootGetters['oidc/oidcAccessToken']) : {}),
  getUser: (state, getters, rootState, rootGetters) => rootGetters['oidc/oidcUser'] || {},
  getUserName: (state, getters) => getters['getUser'].name,
  getUserRole: (state, getters) => {
    const role = cleanApiString(getters['tokenObject'].role).replace(/([A-Z])/g, ' $1').trim();
    return !StringHelper.isNullOrEmpty(role) ? role : null;
  },
  getUserPosition: (state, getters) => {
    // return position or fall back to role
    const position = cleanApiString(state.profile.position);
    const role = getters['getUserRole'];
    return !StringHelper.isNullOrEmpty(position) ? position : !StringHelper.isNullOrEmpty(role) ? role : null;
  },
  getOrgId: (state, getters) => getters['tokenObject'].OrganizationCode,
  userLogin: state => state.profile.email || null,
  userType: (state, getters) => cleanApiString(state.profile.department) || cleanApiString(getters['tokenObject'].OrganizationType),
  getUserFullName: (state, getters) => joinName(state.profile.firstName, state.profile.lastName)
    || joinName(getters['getUser'].givenname, getters['getUser'].surname),
  userId: (state, getters) => getters['getUser'].sub,
  orgId: (state, getters) => getters['tokenObject'].Organization,
  getProfilePicture: state => state.profile.profilePictureUrl || null,
  getAppVersion: state => (state.version['spirion-enterprise-operator'] ? `Version: ${state.version['spirion-enterprise-operator'].toUpperCase()}` : null),
  getPermissions: (state, getters) => getters['tokenObject'].Permission || [],
  /**
   * i'm unsure whether the following are still required
   */
  isProductOwner: (state, getters) => getters['tokenObject'].role === RoleModel.ProductOwner,
  isAdmin: (state, getters) => getters['getPermissions'].includes(claims.Admin),
  isDataPrivacyUser: (state, getters) => getters['tokenObject'].role === RoleModel.DataPrivacyUser, // used to be SecurityAnalyst
  isGeneralUser: (state, getters) => getters['tokenObject'].role === RoleModel.GeneralUser, // used to be BusinessUser
};
