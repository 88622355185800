import Vue from 'vue';
import App from '@/App.vue';
import store from '@/store/index';
import router from '@/router/index';

import '@/plugins';
import '@/directives';
import vuetify from '@/plugins/vuetify/vuetify.plugin';

import '@/scss/alpine-theme.scss';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
