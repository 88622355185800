export default class RoleModel
{
  static ProductOwner = 'ProductOwner';

  static Admin = 'Admin'; // client admin

  static DataPrivacyUser = 'Data Privacy User'; // security analyst

  static GeneralUser = 'General User'; // business user
}
