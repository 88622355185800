export default {
  async fetchProfile({ commit, getters }) {
    const id: string = getters['userId'];
    if (!id || !id.length) {
      return;
    }
    const profileUrl = `${this._vm.$service.UserProfile_Api_Url}Settings/${id}`;
    const response = await this._vm.$api.get(profileUrl, null, 'Your user profile is missing required information. Please edit your profile on the Settings > Personal Settings > Profile page.');
    if (response && response.data) {
      commit('setProfile', response.data);
    }
  },
  async fetchAppVersion({ commit }) {
    const versionPayload = `${this._vm.$service.Infrastructure_Api_Url}version/`;
    fetch(versionPayload)
      .then(response => response.json())
      .then(data => commit('setAppVersion', data));
  },
  inactiveLogout({ dispatch }): void {
    dispatch('snackbar/setMessage', {
      status: 'error',
      text: 'Your session timed out due to inactivity.'
    }, { root: true });
    setTimeout(() => {
      dispatch('oidc/signOutOidc', null, { root: true });
    }, 3000);
  },
};
