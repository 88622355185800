













import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import AlpineLayoutDefault from '@/layouts/AlpineLayoutDefault.vue';
import AlpineLayoutNoSidebar from '@/layouts/AlpineLayoutNoSidebar.vue';
import TypeHelper from '@/helpers/TypeHelper';
import { UserHelper } from './pages/users/UserHelper';

const promptForRenewLimit = 5;
let sessionCheckInterval = null;

export default Vue.extend({
  name: 'AlpineApp',
  components: {
    AlpineLayoutDefault,
    AlpineLayoutNoSidebar,
    AlpineSnackbar: () => import('@/components/snackbars/AlpineSnackbar.vue'),
    MessageDialog: () => import('@/components/ui/AlpineMessageDialog.vue')
  },
  data(){
    return {
      permissionManagerNotified: false,
      userLastActive: new Date(),
      sessionPromptOpen: false
    }
  },
  computed: {
    ...mapGetters('oidc', ['oidcIsAuthenticated', 'oidcAccessTokenExp']),
    /**
     * the following ternary logic should be
     * refactored if we add more layouts
     */
    layout(): string {
      const { layout } = this.$route.meta;
      return !!layout && layout === 'no-sidebar'
        ? 'alpine-layout-no-sidebar'
        : 'alpine-layout-default';
    }
  },
  watch: {
    $route(to) {
      // Note: the if logic clones what is used in AlpineNavBar.vue setMenuLevel method. If this is modified ensure the changes are synced.
      if (to && to.meta && to.meta.claims && !(this as Vue).$permissions(to.meta.claims, to.meta.requireAllClaims)) {
        this.$router.push({ name: 'access-denied' })
      }
    },
    oidcIsAuthenticated(val) {
      if (val) {
        this.checkBrowser();
        this.subscribeOidcTokenExpired();
        this.notifyPermissionsManager();
      }
    }
  },
  mounted() {
    this.checkBrowser();
    this.subscribeOidcTokenExpired();
    this.notifyPermissionsManager();
    this.userLastActive = new Date();
    const that = this;

    window.onbeforeunload = () => {
      // use before unload as beforeDestroy or destroy Vue methods do not fire from the base App.vue component.
      that.subscribeOidcTokenExpired(false);
      clearInterval(sessionCheckInterval);
      document.removeEventListener('click', this.updateUserLastActive);
    }

    window.addEventListener('unload', () => {
      // 1. If the application is open in multiple tabs or multiple windows using the same browser this will force ...
      //    ... a token to renew in the other tabs / window that were not closed when the user navigates the app
      // 2. If the browser window is closed with no other windows open then the user will be required to log in again
      that.removeOidcUser();
    });

    document.addEventListener('click', this.updateUserLastActive);

    // minute interval for showing session timeout dialog to the user
    sessionCheckInterval = setInterval(() => {
      that.checkSession();
    }, 60000);
  },
  methods: {
    ...mapActions('oidc', [
      'signOutOidc', 'removeOidcUser', 'authenticateOidcSilent',
    ]),
    askToRenewSession(minutesLeft: number = promptForRenewLimit) {
      // Prevent dialog clicks from processing a session renew
      document.removeEventListener('click', this.updateUserLastActive);
      this.sessionPromptOpen = true;
      this.$refs.msgDialogAppVue.showConfirm(`You're session will expire in ${minutesLeft} minute(s).  Would you like to stay logged in?`,
        () => { // Yes
          this.sessionPromptOpen = false;
          this.updateUserLastActive();
          this.checkSession(true);
          document.addEventListener('click', this.updateUserLastActive);
        }, 'Session Expiring',
        () => { // No
          this.sessionPromptOpen = false;
          this.signOutOidc();
        });
    },
    async checkSession(renew: boolean = false) {
      if (!this.oidcIsAuthenticated) {
        return;
      }
      if (renew) {
        // This will refresh the token expire time.
        await this.authenticateOidcSilent();
        return;
      }

      const expireIn: number = this.getSessionExpireMinutes();
      const userLastActiveMinutes = this.dateDiffMinutes(new Date(), this.userLastActive);

      if (expireIn > 0 && expireIn <= promptForRenewLimit) {
        // If the user has been active in the last 15 minutes then silent renew the token rather than prompting
        if (!this.sessionPromptOpen && userLastActiveMinutes <= 15) {
          this.checkSession(true);
          return;
        }
        // This will occur up to 5 times per current limit.  It does not show 5 dialogs because vuetify dialogs are static. Allowing the sessionCheckInterval ...
        // ... timer to run lets the message on the dialog update every minute to show current minutes left until the session expires.
        this.askToRenewSession(expireIn);
      }
    },
    updateUserLastActive() {
      this.userLastActive = new Date();
    },
    getSessionExpireMinutes(): number {
      if (!this.oidcIsAuthenticated) {
        return -1;
      }

      const tokenExp = this.oidcAccessTokenExp;
      if (TypeHelper.isNull(tokenExp)) {
        return -1;
      }
      return this.dateDiffMinutes(new Date(tokenExp), new Date());
    },
    dateDiffMinutes(first: Date, second: Date): number {
      try {
        const timeDiff = first.getTime() - second.getTime();
        if (TypeHelper.isNull(timeDiff) || timeDiff <= 0) {
          return -1;
        }

        return parseInt(Math.abs(timeDiff / 60000).toFixed(0), 10);
      }
      catch {
        return -1;
      }
    },
    checkBrowser() {
      try {
        const detect = this.$browserDetect;
        if (!detect || !this.oidcIsAuthenticated) {
          return;
        }
        // isEdge flag does not work in browser detect.  This is due to the Edge user agent data communicating that it is a Chrome browser. As a ...
        // ... workaround we use the additional entry in the user agent string 'edg/'. 'edg/' is correct. It is not 'edge/'
        if (!detect.isChrome || (detect && detect.meta && detect.meta.ua && detect.meta.ua.length && detect.meta.ua.toLowerCase().indexOf('edg/') > -1)) {
          this.$store.dispatch('snackbar/setMessage', {
            text: 'The Sensitive Data Platform is optimized for Chrome. You may experience some unexpected behavior when using any other web browser.',
            status: 'warning',
            timeout: 10000
          });
        }
      }
      catch (err) {
        // nothing to do. catch errors so we don't error the application if the browser detect fails
      }
    },
    subscribeOidcTokenExpired(subscribe: boolean = true) {
      try {
        if (subscribe && !this.oidcIsAuthenticated) {
          // make sure the listener is removeed when users are not authenticated. E.g. we do not want to redirect users when on a public page like health checks
          subscribe = false;
        }
        const listenerTarget = 'vuexoidc:accessTokenExpired';
        if (subscribe) {
          window.addEventListener(listenerTarget, this.handleTokenExpired);
        }
        else {
          window.removeEventListener(listenerTarget, this.handleTokenExpired);
        }
      }
      catch (err) {
        // nothing to do. catch errors so we don't error the application if anything fails
      }
    },
    handleTokenExpired() {
      try {
        this.subscribeOidcTokenExpired(false);
        this.$store.dispatch('session/inactiveLogout')
      }
      catch (err) {}
    },
    notifyPermissionsManager(){
      if (this.permissionManagerNotified || !this.oidcIsAuthenticated){
       return;
      }
      UserHelper.notifyPermissionsManager(this.$api, this.$service);
      this.permissionManagerNotified = true;
    }
  }
});
