import { VueConstructor } from 'vue';
import { ExportToCsv } from 'export-to-csv';
import ArrayHelper from '@/helpers/ArrayHelper'
import store from '@/store';

const defaultOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useTextFile: false,
  useBom: true,
  filename: 'Exported CSV file',
  useKeysAsHeaders: true,
  // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
};

export default {
  install(Vue: VueConstructor) {
    Vue.prototype.$csvExporter = (data: any[], options: {}): void => {
      if (!ArrayHelper.isArraySet(data)) {
        store.dispatch('snackbar/setMessage', {
          text: 'There is no data to export',
          status: 'warning',
        });
        return;
      }
      const opt = {
        ...defaultOptions,
        ...options
      };
      const csvExport = new ExportToCsv(opt);
      csvExport.generateCsv(data);
    };
  }
};
