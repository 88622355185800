import claims from '@/helpers/ClaimsHelper';

export default [
  {
    name: 'scans-dashboard',
    path: '/scans/dashboard',
    component: () => import('@/pages/dashboard/ExecutiveDashboard.vue'),
    meta: {
      claims: claims.ScanDashboard
    }
  },
  {
    name: 'dai-dashboard',
    path: '/data-asset-inventory/dashboard',
    component: () => import('@/pages/dashboard/Sdv3Dashboard.vue')
  },
  {
    name: 'dashboard',
    path: '/dashboard',
    component: () => import('@/pages/dashboard/SpiglassDashboard.vue'),
    meta: {
      layout: 'fullscreen'
    }
  }
];
