import Vue, { VueConstructor } from 'vue';
import store from '@/store';
import $axios from '@/plugins/axios/axios.plugin';
import StringHelper from '@/helpers/StringHelper';
import TypeHelper from '@/helpers/TypeHelper';
import ArrayHelper from '@/helpers/ArrayHelper';

const defaultErrMsg = 'A server Error has occurred, please try again, or contact support if the error continues.';
const defaultSnackTimeout = 6000;
const defaultMinTimeout = 10000;

type SnackbarStatusType = 'success' | 'info' | 'warning' | 'error';

interface SnackbarActionInterface {
  btnIcon?: string;
  btnLabel?: string;
  callback: () => Promise<any>;
}

export interface SnackbarHintInterface {
  text: string;
  action: SnackbarActionInterface;
}

export class SnackbarMessage {
  public id?: number | string;

  public text: string;

  public status?: SnackbarStatusType;

  public timeout?: number;

  public action?: SnackbarActionInterface;

  public hint?: SnackbarHintInterface;

  constructor({
    text, status = 'success', timeout = defaultSnackTimeout, action = null, id = null, hint = null
  }: SnackbarMessage) {
    this.text = text;
    this.status = status;
    this.timeout = timeout;
    this.id = id || new Date().getTime();
    this.action = action;
    this.hint = hint;
  }
}

/**
 * @description handle snackbar messages
 * @param {string} msg custom successfull message
 * @param {string} status color message style; default is 'success'
 */
const handleResponse = (msg: string, status: SnackbarStatusType = null, msgTimeout: number = defaultSnackTimeout, action: SnackbarActionInterface = null, hint: SnackbarHintInterface = null) => {
  if (msg) {
    const payload: SnackbarMessage = {
      text: msg,
      status: StringHelper.isNullOrWhiteSpace(status) ? 'success' : status,
      timeout: TypeHelper.isNull(msgTimeout) || msgTimeout < defaultSnackTimeout
        ? defaultSnackTimeout
        : msgTimeout,
      action,
      hint,
    };
    store.dispatch('snackbar/setMessage', payload);
  }
};

const handleError = (err: any, errMsgOverride: string | string[] = null, msgTimeout: number = defaultSnackTimeout, action: SnackbarActionInterface = null) => {
  // API fluent validation returns an object with an errors array and string title = 'One or more validation errors occurred.'.  If we recieve this ...
  // ... then we set errMsgOverride to read the errors array and display the API validation errors but only if the msg override is not set by the caller.

  // String message overrides are error messages passed from the caller to any of the $api service calls (get, post, etc..)
  const hasStringMessageOverride = !TypeHelper.isNull(errMsgOverride) && TypeHelper.isString(errMsgOverride) && errMsgOverride.length > 0;
  let originalMsgOverride: string = null;

  if ((TypeHelper.isNull(errMsgOverride) || hasStringMessageOverride)
    && err && err.response && err.response.status === 400
    && err.response.data
    && StringHelper.stringsEqualIgnoreCase(err.response.data.title, 'One or more validation errors occurred.')
    && !TypeHelper.isNull(err.response.data.errors)
    && ArrayHelper.isArraySet(Object.keys(err.response.data.errors))) {
    originalMsgOverride = errMsgOverride as string; // allows original message to show above 'The following validation errors occurred:' in the final snack message
    errMsgOverride = ['errors']; // allows API validation handler below to process and add items in API errors array to the message
  }

  // handler for API validation errors and custom errMsgOverride array passed from user management
  if (!TypeHelper.isNull(errMsgOverride) && TypeHelper.isArray(errMsgOverride) && ArrayHelper.isArraySet(errMsgOverride as string[])) {
    const errFields = errMsgOverride as string[];
    errMsgOverride = '';
    try {
      if (err && err.response && err.response.data) {
        const errData = err.response.data;
        let hasFieldMessages: boolean = false;

        const processFieldData = (fieldData: any) => {
          if (fieldData && !TypeHelper.isNull(fieldData) && TypeHelper.isArray(fieldData) && ArrayHelper.isArraySet(fieldData as string[])) {
            const fieldMessages = (fieldData as string[]).join('<br/>');
            if (!StringHelper.isNullOrWhiteSpace(fieldMessages)) {
              if (errMsgOverride.indexOf(fieldMessages) === -1) { // ensure no duplicates
                errMsgOverride += `${(hasFieldMessages ? '<br/>' : '')}${fieldMessages}`;
              }
              hasFieldMessages = true;
            }
          }
        };

        errFields.forEach(field => {
          if (!StringHelper.isNullOrWhiteSpace(field)) {
            if (field === 'errors' && errData[field]) {
              // Handles 'errors' object returned from some API's. It expected to be a property with 1 or more keys all of which have error message arrays.
              // Example data: "errors: { Composition: ["'Composition' must not be empty"] } will show "'Composition' must not be empty" snack message.
              const errorsFieldData = errData[field];
              if (!TypeHelper.isNull(errorsFieldData)) {
                Object.keys(errorsFieldData).forEach((errField) => {
                  processFieldData(errorsFieldData[errField]);
                });
              }
              return; // continue the forEach loop
            }
            // this case handles errData equal to the standard ASP.Net error object like those returned from user management.
            /**
             * A sample error response would be and object with 1 or more properties holding string arrays with the message to show. E.g. similar to "errors" value above.
             *    ContactEmail: ["'EmailAddress' must be unique in the database."]
             *    UserName: ["'Username' must be unique in the database."]
             *    The above with 2 properties would show snack a message for each message in the array, one per line.
             *      E.g. Snack message will be: "'EmailAddress' must be unique in the database.<br />'Username' must be unique in the database."
             */
            processFieldData(errData[field]);
          }
        });

        if (!StringHelper.isNullOrEmpty(errMsgOverride)) {
          errMsgOverride = 'The following validation errors occurred:<ul><li>' + errMsgOverride.split('<br/>').join('</li><li>') + '</li></ul>';
          if (hasStringMessageOverride && !StringHelper.isNullOrEmpty(originalMsgOverride)) {
            // Display original message over validation errors.
            errMsgOverride = `${originalMsgOverride}<br>${errMsgOverride}`;
          }
        }
      }
    }
    catch (err) {
      errMsgOverride = null; // let default message show
    }
  } // End handler for API validation errors processing

  let message = errMsgOverride as string || defaultErrMsg;
  let status: SnackbarStatusType = 'error';
  if (err.response && err.response.data && !StringHelper.isNullOrWhiteSpace(err.response.data.Message)) {
    // show custom error/warning returned from the API.
    status = !err.response.data.isWarning ? 'error' : 'warning';
    message = err.response.data.Message;
  }
  let hint: SnackbarHintInterface = null;
  if (err.response && err.response.headers) {
    const sentryId = err.response.headers['sp-sentryid'];
    if (sentryId) {
      hint = {
        text: `Error ID: ${JSON.stringify(sentryId)}`,
        action: {
          btnIcon: '$alpine-copy',
          callback: () => navigator.clipboard.writeText(sentryId),
        },
      };
      msgTimeout = defaultMinTimeout > msgTimeout
        ? defaultMinTimeout
        : msgTimeout;
    }
  }
  handleResponse(message, status, msgTimeout, action, hint);
};

async function processRequest(processor: () => Promise<any>, msg: string, errMsg: string | string[], msgTimeout: number, action: SnackbarActionInterface = null, customErrHandler: (errResponse: any) => boolean = null) {
  let suppressSnackError = false;
  try {
    suppressSnackError = !TypeHelper.isNull(errMsg) && TypeHelper.isString(errMsg) && (errMsg as string).toLowerCase() === 'suppress';
  }
  catch (err) {
    suppressSnackError = false;
  }

  try {
    if (!processor) {
      return null;
    }

    const processCustomErrHandler = (serviceError: any): boolean => {
      if (TypeHelper.isNull(customErrHandler) || !serviceError || !serviceError.response) {
        return false;
      }
      try {
        const answer = customErrHandler(serviceError.response);
        return TypeHelper.isNull(answer) ? false : answer;
      }
      catch (err) {
        return false;
      }
    }

    let success: boolean = true;
    const response = await processor()
      .catch((serviceErr) => {
        // handle errors from the service
        const handledByCustomHandler = processCustomErrHandler(serviceErr);
        if (!suppressSnackError && !handledByCustomHandler) {
          handleError(serviceErr, errMsg, msgTimeout, action);
        }
        success = false;
      });

    if (!success) {
      return null;
    }

    handleResponse(msg);
    return response;
  }
  catch (err) {
    // unhandled error. the custom error handler does not apply here as it is only for service error custom handling
    if (!suppressSnackError) {
      handleError(err, errMsg, null, action);
    }
    return null;
  }
}

interface ServiceApiInterface {
  /**
   * @param {string} url api microservice url
   * @param {string} msg custom message to pass along to snackbar
   * @param {string} errMsg custom error message to pass along to the snackbar
   * @param {number} msgTimeout override snack message default timeout of 6 seconds. Will apply only if greater than 6 seconds (E.g. 6000)
   * @param {any} action shows an action button on the error snack.  See DialogAnyFindManage.vue for an example.
   * @param {any} customErrHandler apply a custom handler for API errors. return true when the specific error is handled by the caller, false to show generic api error message. See TargetImportDialog.vue for an example.
   */
  get(url: string, msg: string, errMsg: string | string[], msgTimeout: number, action: SnackbarActionInterface, customErrHandler?: (errResponse: any) => boolean): Promise<any>;
  post(url: string, payload: any, msg: string, errMsg: string | string[], headerOption: any, msgTimeout: number, customErrHandler?: (errResponse: any) => boolean): Promise<any>;
  put(url: string, payload: any, msg: string, errMsg: string | string[], msgTimeout: number, customErrHandler?: (errResponse: any) => boolean): Promise<any>;
  patch(url: string, payload: any, msg: string, errMsg: string | string[], msgTimeout: number, customErrHandler?: (errResponse: any) => boolean): Promise<any>;
  delete(url: string, payload: any, msg: string, errMsg: string | string[], msgTimeout: number, customErrHandler?: (errResponse: any) => boolean): Promise<any>;
}

export default {
  install(Vue: VueConstructor) {
    Vue.prototype.$api = ({
      async get(url: string, msg: string = null, errMsg: string | string[] = null, msgTimeout: number = null, action: SnackbarActionInterface, customErrHandler: (errResponse: any) => boolean = null): Promise<any> {
        return processRequest(() => $axios.get(url), msg, errMsg, msgTimeout, action, customErrHandler);
      },
      async post(url: string, payload: any, msg: string = null, errMsg: string | string[] = null, headerOption: any = null, msgTimeout: number = null, customErrHandler: (errResponse: any) => boolean = null) {
        return processRequest(() => $axios.post(url, payload, headerOption), msg, errMsg, msgTimeout, null, customErrHandler);
      },
      async put(url: string, payload: any, msg: string = null, errMsg: string | string[] = null, msgTimeout: number = null, customErrHandler: (errResponse: any) => boolean = null) {
        return processRequest(() => $axios.put(url, payload), msg, errMsg, msgTimeout, null, customErrHandler);
      },
      async patch(url: string, payload: any, msg: string = null, errMsg: string | string[] = null, msgTimeout: number = null, customErrHandler: (errResponse: any) => boolean = null) {
        return processRequest(() => $axios.patch(url, payload), msg, errMsg, msgTimeout, null, customErrHandler);
      },
      async delete(url: string, payload: any = null, msg: string = null, errMsg: string | string[] = null, msgTimeout: number = null, customErrHandler: (errResponse: any) => boolean = null) {
        if (payload) {
          return processRequest(() => $axios.delete(url, { data: payload }), msg, errMsg, msgTimeout, null, customErrHandler);
        }
        return processRequest(() => $axios.delete(url), msg, errMsg, msgTimeout, null, customErrHandler);
      }
    } as ServiceApiInterface);
  }
};

declare module 'vue/types/vue' {
  interface Vue {
    $api: ServiceApiInterface;
  }
}
