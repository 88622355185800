export default [
  {
    name: 'notifications',
    path: '/notifications',
    component: () => import('@/pages/notifications/NotificationPage.vue'),
  },
  // {
  //   name: 'view-details-notifications',
  //   path: '/notifications/details',
  //   component: () => import('@/pages/notifications/ViewDetailsNotification.vue'),
  // },
]
