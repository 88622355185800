import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';

if (process.env.NODE_ENV === 'production') {
  try {
    Sentry.init({
      dsn: process.env.VUE_APP_SENTRY_DSN,
      release: process.env.VUE_APP_RELEASE,
      environment: process.env.VUE_APP_ENVIRONMENT,
      integrations: [new VueIntegration({ Vue, attachProps: true })],
    });
  }
  catch (err) {
    // A sentry init failure should not prevent the application from running. E.g. Sentry is not required for any console web functionality so an init failure can be safely ignored.
  }
}
