import Vue from 'vue';

import vueTheMask from 'vue-the-mask';
import DatetimePicker from 'vuetify-datetime-picker';
import browserDetect from 'vue-browser-detect-plugin';

import $axios from '@/plugins/axios/axios.plugin';
import services from '@/plugins/services/services.plugin';
import permissions from '@/plugins/permissions/permissions.plugin';
import exportToCsv from '@/plugins/exportToCsv/exportToCsv.plugin';

// BaseApiService is a legacy plugin that should be depracated
import BaseApiService from '@/middleware/api/BaseApiService';

/** logging tools */
import '@/plugins/sentry/sentry.plugin';

Vue.use(vueTheMask);
Vue.use(DatetimePicker);
Vue.use(services);
Vue.use(permissions);
Vue.use(exportToCsv);
Vue.use(browserDetect);

Vue.prototype.$axios = $axios;
Vue.prototype.$service = new BaseApiService();
