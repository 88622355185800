import claims from '@/helpers/ClaimsHelper'

export default [
  {
    name: 'agents',
    path: '/agents',
    component: () => import('@/pages/agents/PageAgentManagement.vue'),
    meta: {
      claims: claims.AgentsAndEndpoints
    }
  },
  {
    name: 'agents-tags',
    path: '/agents/tag-management',
    component: () => import('@/pages/agents/tags/PageTagManagement.vue'),
    meta: {
      claims: claims.ViewTagList
    }
  },
  {
    name: 'agents-policies',
    path: '/agents/policies',
    component: () => import('@/pages/agentConfiguration/PagePolicyConfiguration.vue'),
    meta: {
      claims: claims.AssignPolicyREAD
    }
  },
  {
    name: 'agents-policies-add',
    path: '/agents/policies/add',
    component: () => import('@/pages/agentConfiguration/policies/PolicyWizardRoot.vue'),
    meta: {
      claims: claims.PolicyConfigWRITE,
      layout: 'fullscreen'
    }
  },
  {
    name: 'agents-policies-edit',
    path: '/agents/policies/edit/:id/:name',
    component: () => import('@/pages/agentConfiguration/policies/PolicyWizardRoot.vue'),
    meta: {
      claims: claims.PolicyConfigWRITE,
      layout: 'fullscreen'
    }
  },
  {
    name: 'agents-installation',
    path: '/agents/installation',
    component: () => import('@/pages/agents/PageAgentInstallation.vue'),
    meta: {
      claims: claims.AgentConfigurationREAD
    }
  },
  {
    name: 'agents-installation-add',
    path: '/agents/installation/add',
    component: () => import('@/pages/agentConfiguration/installation/InstallationWizardRoot.vue'),
    meta: {
      claims: claims.AgentConfigurationWRITE,
      layout: 'fullscreen'
    },
    props: {
      isEdit: false,
    },
  },
  {
    name: 'agents-installation-edit',
    path: '/agents/installation/edit/:id',
    component: () => import('@/pages/agentConfiguration/installation/InstallationWizardRoot.vue'),
    meta: {
      claims: claims.AgentConfigurationWRITE,
      layout: 'fullscreen'
    },
  },
  {
    name: 'agents-registration',
    path: '/agents/registration',
    component: () => import('@/pages/agentConfiguration/PageAgentRegistration.vue'),
    meta: {
      claims: claims.Installation
    }

  }
]
