import dayjs from 'dayjs';
import { DateColumn } from '@/components/utility/GridUtilities';
import StringHelper from '@/helpers/StringHelper';

export const getFormattedDate = (value: string | Date): string => dayjs(value).format('hh:mm MM/DD/YYYY');

export const getFormattedDateDefault = (value: string | Date): string => dayjs(value).format('MM/DD/YYYY HH:mm');

export const getDateWithUTC = (value: string | Date): string => dayjs(value).format('MM/DD/YYYY hh:mm A');

export const getDateTimeWithUTC = (value: string | Date): string => dayjs(value).format('MM/DD/YYYY HH:mm:ss');

export const getFormattedDatePicker = (value: string | Date): string => dayjs(value).format('YYYY-MM-DD');

export const calculateMinutesFromNow = (d: any): number => {
  try {
    // Use toDisplayString first as that handles converting API UTC dates to local time
    const localDateString = DateColumn.toDisplayString(d); // returns '' for invalid dates
    if (StringHelper.isNullOrEmpty(localDateString)) {
      return null;
    }
    // Get the minutes diff now - date supplied
    const diff = new Date().getTime() - new Date(localDateString).getTime(); // diff in milliseconds
    return Math.round((diff / 1000) / 60);
  }
  catch (err) {
    return null;
  }
}
