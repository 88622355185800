import { vuexOidcCreateStoreModule } from 'vuex-oidc';
import oidcSettings from '@/plugins/oidc';
import store from '@/store';

export default
  vuexOidcCreateStoreModule(
    oidcSettings,
    {
      namespaced: true,
      // allows adding window event listeners. E.g. window.addEventListener('vuexoidc:accessTokenExpired', ...). see App.vue
      dispatchEventsOnWindow: true,
    },
    // Optional OIDC event listeners
    {
    //   userLoaded: user => console.log('OIDC user is loaded', user),
    //   userUnloaded: () => console.log('OIDC user is unloaded'),
    //   accessTokenExpiring: () => console.log('Access token will expire'),
    //   accessTokenExpired: () => store.dispatch('session/inactiveLogout'),
    //   silentRenewError: () => console.log('OIDC user is unloaded'),
    //   userSignedOut: () => console.log('OIDC user is signed out'),
    //   oidcError: payload => console.log(`An error occured at ${payload.context}:`, payload.error),
    },
  );
