import { SnackbarMessage } from '@/plugins/services/services.plugin';
import TypeHelper from '@/helpers/TypeHelper';

interface SnackbarStateInterface {
  messages: SnackbarMessage[];
}

const state: SnackbarStateInterface = {
  messages: [],
};

const actions = {
  /**
   * @description this action builds a message object and adds it to the message bag
   * @param {string|SnackbarMessage} payload payload can be a string or a message
   */
  setMessage({ commit, dispatch }: any, payload: string | SnackbarMessage): void {
    const msg = typeof payload === 'string'
      ? { text: payload }
      : payload;
    const msgObj = new SnackbarMessage(msg);
    commit('SET_MSG', msgObj);
    setTimeout(() => dispatch('removeMessage', msgObj.id), msgObj.timeout);
  },
  /**
   * @description this action removes a message from message bag
   * @param {number|string} id message id used to remove from message bag
   */
  removeMessage({ commit }: any, id: number | string): void {
    commit('REMOVE_MSG', id);
  },
};

const getters = {};

const mutations = {
  SET_MSG(state: SnackbarStateInterface, msg: SnackbarMessage): void {
    if (TypeHelper.isNull(state) || TypeHelper.isNull(msg)) {
      return;
    }
    // Prevent duplicate snack messages from showing. E.g. if the messages stack already has the message then there is no need ...
    // ... to show the same message again.  This will usually happen on pages that make multiple API calls that fail with the ...
    // ... generic server error message.
    // eslint-disable-next-line
    const getHintText = (message: SnackbarMessage): string => {
      return TypeHelper.isNull(message.hint) || !message.hint.text ? '' : message.hint.text;
    };

    if (state.messages && state.messages.length && state.messages.some(m => m.text === msg.text && getHintText(m) === getHintText(msg))) {
      return;
    }
    state.messages.push(msg);
  },
  REMOVE_MSG(state: SnackbarStateInterface, id: number | string): void {
    state.messages = state.messages.filter(msg => msg.id !== id);
  }
};

export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true,
};
