import claims from '@/helpers/ClaimsHelper';

export default [
  {
    name: 'activity-dashboard',
    path: '/watcher/dashboard',
    component: () => import('@/pages/activity/dashboard/PageActivityDashboard.vue'),
    meta: {
      claims: claims.DashboardREAD
    },
  },
  {
    name: 'query-list',
    path: '/watcher/queries',
    component: () => import('@/pages/activity/PageQueryList.vue'),
    meta: {
      claims: claims.QueryREAD
    }
  },
  {
    name: 'add-query',
    path: '/activity/queries/add',
    component: () => import('@/pages/activity/PageQueryManage.vue'),
    meta: {
      claims: claims.QueryWRITE
    }
  },
  {
    name: 'edit-query',
    path: '/activity/queries/edit/:id',
    component: () => import('@/pages/activity/PageQueryManage.vue'),
    meta: {
      claims: claims.QueryWRITE
    }
  },
  {
    name: 'incident-definitions-list',
    path: '/watcher/incidents-definitions',
    component: () => import('@/pages/activity/PageIncidentDefinitions.vue'),
    meta: {
      claims: claims.IncidentDefinitionREAD
    }
  },
  // {
  //   name: 'add-incident-definition',
  //   path: '/activity/incidents/definitions/add',
  //   component: () => import('@/pages/activity/PageIncidentDefinitionsManage.vue'),
  //   meta: {
  //     claims: [claims.IncidentDefinitionWRITE, claims.QueryREAD, claims.DefinitionPlaybookWRITE],
  //     requireAllClaims: true
  //   }
  // },
  {
    name: 'new-incident-definition-wizard',
    path: '/watcher/activity-watcher/wizard',
    component: () => import('@/pages/activity/incidents/PageIncidentDefinitionsWizard.vue'),
    meta: {
      layout: 'fullscreen',
      claims: [claims.IncidentDefinitionREAD, claims.IncidentDefinitionWRITE, claims.QueryREAD, claims.QueryWRITE, claims.DefinitionPlaybookREAD, claims.DefinitionPlaybookWRITE],
      requireAllClaims: true
    }
  },
  {
    name: 'edit-incident-definition-wizard',
    path: '/watcher/activity-watcher/wizard/:id',
    component: () => import('@/pages/activity/incidents/PageIncidentDefinitionsWizard.vue'),
    meta: {
      layout: 'fullscreen',
      claims: [claims.IncidentDefinitionREAD, claims.IncidentDefinitionWRITE, claims.QueryREAD, claims.QueryWRITE, claims.DefinitionPlaybookREAD, claims.DefinitionPlaybookWRITE],
      requireAllClaims: true
    },
  },
  // {
  //   name: 'edit-incident-definition',
  //   path: '/activity/incidents/definitions/edit/:id',
  //   component: () => import('@/pages/activity/PageIncidentDefinitionsManage.vue'),
  //   meta: {
  //     claims: [claims.IncidentDefinitionREAD, claims.QueryREAD, claims.DefinitionPlaybookREAD],
  //     requireAllClaims: true
  //   }
  // },
  {
    name: 'incident-details',
    path: '/activity/incidents/details/:id',
    component: () => import('@/pages/activity/PageIncidentDetails.vue'),
    meta: {
      claims: claims.IncidentREAD
    }
  },
  {
    name: 'incident-playbooks',
    path: '/activity/incidents/playbooks',
    component: () => import('@/pages/activity/incidents/playbooks/PageIncidentPlaybooks.vue'),
    meta: {
      claims: claims.DefinitionPlaybookREAD
    }
  },
  {
    name: 'incident-playbooks-builder',
    path: '/activity/incidents/playbooks/builder',
    component: () => import('@/pages/activity/incidents/playbooks/PageIncidentPlaybookBuilder.vue'),
    meta: {
      layout: 'fullscreen',
      claims: [claims.DefinitionPlaybookWRITE, claims.DefinitionPlaybookREAD],
      requireAllClaims: true
    },
  },
  {
    name: 'incident-playbooks-editor',
    path: '/activity/incidents/playbooks/builder/:playbookId',
    component: () => import('@/pages/activity/incidents/playbooks/PageIncidentPlaybookBuilder.vue'),
    meta: {
      layout: 'fullscreen',
      claims: [claims.DefinitionPlaybookWRITE, claims.DefinitionPlaybookREAD],
      requireAllClaims: true
    },
  },
  {
    name: 'event-details',
    path: '/activity/incidents/details/:incidentId/event/:eventLogId',
    component: () => import('@/pages/activity/PageEventDetails.vue'),
    meta: {
      claims: claims.IncidentREAD
    }
  },
  {
    name: 'current-incidents',
    path: '/watcher/incidents/current',
    component: () => import('@/pages/activity/PageCurrentIncidents.vue'),
    meta: {
      claims: claims.IncidentREAD
    }
  },
  {
    name: 'incidents-archive',
    path: '/watcher/incidents/current',
    component: () => import('@/pages/activity/PageCurrentIncidents.vue'),
    meta: {
      claims: claims.IncidentArchiveREAD,
      currentTab: 'tabHistory'
    }
  },
  {
    name: 'sensitive-data-watcher-list',
    path: '/watcher/data-watchers',
    component: () => import('@/pages/activity/watchers/PageWatchersList.vue'),
    meta: {
      claims: [claims.ActivityWatcher, claims.PolicyConfigREAD],
      requireAllClaims: true
    },
  },
  {
    name: 'new-watcher-wizard',
    path: '/activity/watchers/wizard',
    component: () => import('@/pages/activity/watchers/PageWatcherWizard.vue'),
    meta: {
      layout: 'fullscreen',
      claims: [claims.ActivityWatcher, claims.PolicyConfigREAD, claims.PolicyConfigWRITE],
      requireAllClaims: true
    },
  },
  {
    name: 'edit-watcher-wizard',
    path: '/activity/watchers/wizard/:id',
    component: () => import('@/pages/activity/watchers/PageWatcherWizard.vue'),
    meta: {
      layout: 'fullscreen',
      claims: [claims.ActivityWatcher, claims.PolicyConfigREAD, claims.PolicyConfigWRITE],
      requireAllClaims: true
    },
  },
  {
    name: 'activity-watcher-policies',
    path: '/watcher/activity-watcher-policies',
    component: () => import('@/pages/activity/PagePolicyWatchersList.vue'),
    meta: {
      claims: [claims.ActivityWatcher, claims.PolicyConfigREAD],
      requireAllClaims: true
    },
  },
  {
    name: 'new-activity-watcher-policy',
    path: '/watcher/activity-watcher-policy',
    component: () => import('@/pages/activity/PageNewActivityWatcherPolicy.vue'),
    meta: {
      layout: 'fullscreen',
      claims: [claims.ActivityWatcher, claims.PolicyConfigREAD, claims.PolicyConfigWRITE],
      requireAllClaims: true
    },
  },
  {
    name: 'update-activity-watcher-policy',
    path: '/watcher/activity-watcher-policy/:id',
    component: () => import('@/pages/activity/PageNewActivityWatcherPolicy.vue'),
    meta: {
      layout: 'fullscreen',
      claims: [claims.ActivityWatcher, claims.PolicyConfigREAD, claims.PolicyConfigWRITE],
      requireAllClaims: true
    },
  },
  {
    name: 'data-watcher-results',
    path: '/data-watcher/results',
    component: () => import('@/pages/scan/SearchResults.vue'),
    meta: {
      claims: [claims.ScanResultsRead, claims.ActivityWatcher],
      requireAllClaims: true
    },
    props: {
      isWatcherMode: true,
    }
  },
];
