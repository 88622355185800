import 'dayjs/locale/es'
import dayjs from 'dayjs'
import { HealthCheckApiInfo, HealthCheckApiType } from '@/helpers/UtilityClasses';

export default class BaseApiService {
  UserList_Api_Url =
    `${process.env.VUE_APP_IdentityAuthorityBaseApi}api/`;

  // Note: The user profile services were moved to IA per API ticket AL-11944. To avoid having to re-map all uses of the ...
  //       ... property below in the application we just updated it to use the IA profile APIs
  UserProfile_Api_Url =
    `${process.env.VUE_APP_IdentityAuthorityBaseApi}api/`;

  Organization_Api_Url =
    `${process.env.VUE_APP_OrganizationBaseApi}api/`;

  Registration_Api_Url =
    `${process.env.VUE_APP_RegistrationBaseApi}api/`;

  Remediation_Api_Url =
    `${process.env.VUE_APP_RemediationBaseApi}api/`;

  Ingress_Api_Url =
    `${process.env.VUE_APP_IngressBaseApi}api/`;

  IncidentDefinition_Api_Url =
    `${process.env.VUE_APP_IncidentDefinitionBaseApi}api/`;

  Heartbeat_Api_Url =
    `${process.env.VUE_APP_HeartbeatBaseApi}api/`;

  IncidentManagement_Api_Url =
    `${process.env.VUE_APP_IncidentManagementBaseApi}api/`;

  IncidentPersistence_Api_Url =
    `${process.env.VUE_APP_IncidentPersistenceBaseApi}api/`;

  AppVersion_Api_Url = process.env.VUE_APP_OrganizationBaseApiVersion;

  SearchController_Api_Url = `${process.env.VUE_APP_SearchControllerBaseApi}api/`;

  DataWatcher_Api_Url = `${process.env.VUE_APP_DataWatcherBaseApi}api/`;

  Compliance_SubjectRequest_Api_Url = `${process.env.VUE_APP_ComplianceSubjectRequestBaseApi}api/`;

  Compliance_FullText_Api_Url = `${process.env.VUE_APP_ComplianceFullTextBaseApi}api/`;

  TasksSchedulerAPI = `${process.env.VUE_APP_TasksSchedulerAPI}api/`;

  Reporting_Api_Url = `${process.env.VUE_APP_ReportingBaseAPI}api/`;

  // Note: Do not add 'api/' to this.  It will break the call to get the app version that shows in the menu.
  Infrastructure_Api_Url = `${process.env.VUE_APP_InfrastructureApi}`;

  CompliancePIIDataTypesApi_Url = `${process.env.VUE_APP_CompliancePIIDataTypesApi}api/`;

  DataAssetInventory_Api_Url = `${process.env.VUE_APP_DataAssetInventoryApi}api/`;

  AgentController_Api_Url = `${process.env.VUE_APP_AgentControllerBaseApi}api/`;

  SearchHistory_Api_Url = `${process.env.VUE_APP_SearchHistoryBaseApi}api/`;

  UtilityService_Api_Url = `${process.env.VUE_APP_UtilityServiceBaseApi}api/`;

  Compliance_DataSubjectRequest_Api_Url = `${process.env.VUE_APP_ComplianceDataSubjectRequestBaseApi}api/`;

  Compliance_Association_Api_Url = `${process.env.VUE_APP_ComplianceAssociationBaseApi}api/`;

  SdmTranslator_Api_Url = `${process.env.VUE_APP_SdmTranslatorBaseApi}api/`;

  SearchPersistence_Api_Url =`${process.env.VUE_APP_SearchPersistance}api/`;

  Splunk_Api_Url = `${process.env.VUE_APP_SplunkAPI}api/`;

  ResultsProcessing_Api_Url = `${process.env.VUE_APP_ResultsProcessing}api/`;

  get healthCheckApiList(): HealthCheckApiInfo[] {
    const buildPath = (apiUrl: string, methodName: string = 'healthcheck') => `${apiUrl}${methodName}`;
    // Note: HealthCheck.vue sorts this list by name.
    return [
      new HealthCheckApiInfo('Heartbeat', buildPath(this.Heartbeat_Api_Url)),
      new HealthCheckApiInfo('Organization', buildPath(this.Organization_Api_Url)),
      new HealthCheckApiInfo('Registration', buildPath(this.Registration_Api_Url)),
      new HealthCheckApiInfo('Remediation', buildPath(this.Remediation_Api_Url)),
      new HealthCheckApiInfo('Search Controller', buildPath(this.SearchController_Api_Url)),
      new HealthCheckApiInfo('Task Scheduler', buildPath(this.TasksSchedulerAPI)),
      new HealthCheckApiInfo('Data Watcher', buildPath(this.DataWatcher_Api_Url)),
      new HealthCheckApiInfo('Data Asset Inventory', buildPath(this.DataAssetInventory_Api_Url)),
      new HealthCheckApiInfo('Compliance Subject Request', buildPath(this.Compliance_SubjectRequest_Api_Url, 'v2/healthcheck'), HealthCheckApiType.Compliance),
      new HealthCheckApiInfo('Compliance Full Text', buildPath(this.Compliance_FullText_Api_Url, 'v1/FulltextSearch/healthcheck'), HealthCheckApiType.Compliance),
      new HealthCheckApiInfo('Compliance PII Data Types', buildPath(this.CompliancePIIDataTypesApi_Url), HealthCheckApiType.Compliance),
      new HealthCheckApiInfo('Compliance Data Subject Request', buildPath(this.Compliance_DataSubjectRequest_Api_Url, 'datasubjectrequests/healthcheck'), HealthCheckApiType.Compliance),
      new HealthCheckApiInfo('Compliance Association', buildPath(this.Compliance_Association_Api_Url, 'v1/association/healthcheck'), HealthCheckApiType.Compliance),
      new HealthCheckApiInfo('Agent Controller', buildPath(this.AgentController_Api_Url)),
      new HealthCheckApiInfo('Search History', buildPath(this.SearchHistory_Api_Url)),
      new HealthCheckApiInfo('Utility', buildPath(this.UtilityService_Api_Url)),
      new HealthCheckApiInfo('SDM Translator', buildPath(this.SdmTranslator_Api_Url)),
      new HealthCheckApiInfo('Infrastructure', buildPath(this.Infrastructure_Api_Url, 'api/healthcheck')),
      new HealthCheckApiInfo('Reporting', buildPath(this.Reporting_Api_Url)),
      new HealthCheckApiInfo('Identity Authority', buildPath(this.UserList_Api_Url)),
      new HealthCheckApiInfo('Search Persistence', buildPath(this.SearchPersistence_Api_Url)),
      new HealthCheckApiInfo('Incident Persistence', buildPath(this.IncidentPersistence_Api_Url), HealthCheckApiType.Watcher),
      new HealthCheckApiInfo('Incident Definition', buildPath(this.IncidentDefinition_Api_Url), HealthCheckApiType.Watcher),
      new HealthCheckApiInfo('Incident Management', buildPath(this.IncidentManagement_Api_Url), HealthCheckApiType.Watcher),
      new HealthCheckApiInfo('Ingress', buildPath(this.Ingress_Api_Url)),
      //new HealthCheckApiInfo('Splunk API', buildPath(this.Splunk_Api_Url))
    ];
  }

  scriptApiUrl()
  {
    return this.Remediation_Api_Url
  }

  // dateformat
  getFormattedDate = (value) =>
  {
    dayjs(value).format('hh:mm MM/DD/YYYY')
  }

  getFormattedDateDefault = (value) =>
  {
    dayjs(value).format('MM/DD/YYYY HH:mm')
  }

  getDateWithUTC = (value) =>
  {
    dayjs(value).format('MM/DD/YYYY hh:mm A')
  }

  getDateTimeWithUTC = (value) =>
  {
    dayjs(value).format('MM/DD/YYYY HH:mm:ss')
  }
}
