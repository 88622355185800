import Vue from 'vue';
import Vuex from 'vuex';
import sessionModule from './session';
import snackbarModule from './snackbar';
import oidcModule from './oidc';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true, // this may cause caution errors
  modules: {
    session: sessionModule,
    oidc: oidcModule,
    snackbar: snackbarModule
  },
});
