import TypeHelper from '@/helpers/TypeHelper';
import ArrayHelper from '@/helpers/ArrayHelper';
import { Pair, NameValuePair, IEnumDisplayPosition } from '@/helpers/UtilityClasses';

interface IEnum {
  [id: number]: string;
}

export default abstract class EnumUtility
{
  public static getNameValuePairs(e: any,
    displayOverrides: Pair<number, string>[] = null,
    toDisplayString: (value: number) => string = null,
    displayPositionOverrides: IEnumDisplayPosition[] = null): NameValuePair<number>[]
  {
    const ret = Object.keys(e)
      .map((key: string) => parseInt(key, 10))
      .filter((rawValue: number) => !isNaN(rawValue))
      .map((value: number) => {
        let display: string = toDisplayString !== null ? toDisplayString(value) : e[value];
        if (displayOverrides) {
          const override = displayOverrides.filter((s: Pair<number, string>) => s.first === value);
          if (override && override.length > 0) {
            display = override[0].second;
          }
        }
        if (TypeHelper.isNull(display)) {
          return null;
        }
        return new NameValuePair<number>(display, value);
      })
      .filter(p => p !== null);

    if (ArrayHelper.isArraySet(displayPositionOverrides)) {
      const findItem = (value: number): NameValuePair<number> => {
        const search = ret.filter((r: NameValuePair<number>) => r.value === value);
        return ArrayHelper.isArraySet(search) ? search[0] : null;
      };

      displayPositionOverrides.forEach((p: IEnumDisplayPosition) => {
        const itemToMove = findItem(p.value);
        const itemToMoveIndex = ret.indexOf(itemToMove);
        if (itemToMove !== null) {
          const positionBeforeItem = findItem(p.positionBefore);
          if (positionBeforeItem !== null) {
            const testNewIndex = ret.indexOf(positionBeforeItem);
            if (testNewIndex >= 0) {
              // we know the position before item is there so remove itemToMove, get new index to move to and insert.
              ret.splice(itemToMoveIndex, 1);
              ret.splice(ret.indexOf(positionBeforeItem), 0, itemToMove);
            }
          }
        }
      });
    }

    return ret;
  }

  public static hasFlag(value: number, flag: number): boolean
  {
    // some enums can be 64-bit (like ClientModules)
    return TypeHelper.and(value, flag) === flag;
  }

  public static addFlag(value: number, flag: number): number
  {
    // eslint-disable-next-line
    value |= flag;
    return value;
  }

  public static removeFlag(value: number, flag: number): number
  {
    // eslint-disable-next-line
    value &= ~flag;
    return value;
  }

  public static flagsEnumToDisplay(typeValue: number, type: IEnum, getString: (t: number) => string, allItemsValue: number = null, sort: boolean = true)
  {
    let names: string[] = [];
    for (const x in type) {
      if (!type.hasOwnProperty(x)) {
        // eslint-disable-next-line
        continue;
      }
      const temp: any = type[x];
      if (typeof (temp) === 'number') {
        const value: number = temp;
        if (value === 0) {
          if (typeValue !== 0) {
            // eslint-disable-next-line
            continue;
          }
          names = [getString(value)];
          break;
        }
        if (!EnumUtility.hasFlag(typeValue, value)) {
          // eslint-disable-next-line
          continue;
        }
        // E.g. if a flags enum is listing all possible values because typeValue is the 'all' enum value then we do not need to print the ...
        // ... all items text.  See AceRightsUtil in commonEnums.ts as an example.  The FullControl option is a sum of all other options ...
        // ... so when typeValue equals FullControl then flagsEnumToDisplay will list all enum values comma separated excluding FullControl.
        if (allItemsValue !== null && value === allItemsValue) {
          // eslint-disable-next-line
          continue;
        }
        names.push(getString(value));
      }
    }
    if (!names.length) {
      return '';
    }
    if (names.length === 1) {
      return names[0];
    }
    return sort ? names.sort().join(', ') : names.join(', ');
  }
}
