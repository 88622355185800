import claims from '@/helpers/ClaimsHelper'

export default [
  {
    name: 'scans',
    path: '/scans',
    component: () => import('@/pages/scan/ScanLandingComponent.vue'),
    meta: {
      claims: claims.ViewScanList
    }
  },
  {
    name: 'scans-wizard',
    path: '/scans/wizard',
    component: () => import('@/pages/scan/ScanPage.vue'),
    meta: {
      claims: claims.ConfigureNewScan,
      layout: 'fullscreen'
    }
  },
  {
    name: 'scans-wizard-edit',
    path: '/scans/wizard/:id',
    component: () => import('@/pages/scan/ScanPage.vue'),
    meta: {
      claims: claims.EditScan,
      layout: 'fullscreen'
    }
  },
  {
    name: 'prefilter-results',
    path: '/scans/prefilter-results',
    component: () => import('@/pages/scan/PrefilterScanResults.vue'),
    meta: {
      claims: claims.ScanResultsRead
    }
  },
  {
    name: 'search-results',
    path: '/scans/results',
    component: () => import('@/pages/scan/SearchResultsByLocation.vue'),
    meta: {
      claims: claims.ScanResultsRead
    }
  },
  // search results alt is scan results by match and is fully functional. e.g. the old results page before results by location was implemented.
  {
    name: 'search-results-alt',
    path: '/scans/results-alt',
    component: () => import('@/pages/scan/SearchResults.vue'),
    meta: {
      claims: claims.ScanResultsRead
    }
  }
  //{
  //  name: 'scans-review-agent',
  //  path: '/scans/scans-review-agent/:id',
  //  component: () => import('@/pages/scan/ScanReviewSelectedAgents.vue'),
  //  meta: {
  //    claims: claims.EditScan
  //  }
  //}
]
