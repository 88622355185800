





import Vue from 'vue';
import StringHelper from '@/helpers/StringHelper';

export default Vue.extend({
  name: 'AlpineIcon',
  props: {
    name: {
      type: String,
      default: '',
      required: true,
    },
  },
  computed: {
    alpineIcon() {
      return !StringHelper.isNullOrEmpty(this.name)
        ? () => import(`@/assets/icons/${this.name}.svg`)
        : null
    },
  },
});
