import claims from '@/helpers/ClaimsHelper'

export default [
  {
    name: 'settings-classifications',
    path: '/settings/global-classifications',
    component: () => import('@/pages/settings/classifications/PageManageClassifications.vue'),
    meta: {
      claims: claims.AccessSystemAdminSettings
    }
  },
  {
    name: 'settings-data-types',
    path: '/settings/global-data-types',
    component: () => import('@/pages/settings/data-types/PageManageDataTypes.vue'),
    meta: {
      claims: claims.AccessSystemAdminSettings
    }
  },
  {
    name: 'data-type-details',
    path: '/settings/data-type-details',
    component: () => import('@/pages/settings/data-types/DataTypeDetails.vue'),
    meta: {
      claims: claims.AccessSystemAdminSettings
    }
  },
  {
    name: 'settings-profile',
    path: '/settings/profile',
    component: () => import('@/pages/users/UserProfileComponent.vue'),
  },
  {
    name: 'global-settings',
    path: '/application-settings/scans-settings',
    component: () => import('@/pages/settings/GlobalSettingsComponent.vue'),
    meta: {
      claims: claims.AccessSystemAdminSettings
    }
  },
  {
    name: 'user-management',
    path: '/settings/user-management',
    component: () => import('@/pages/users/UserManagementComponent.vue'),
    meta: {
      claims: [claims.UserLIST, claims.RoleREAD, claims.UserProfileREAD],
      requireAllClaims: true
    }
  },
  {
    name: 'role-permissions',
    path: '/settings/role-permissions',
    component: () => import('@/pages/users/PageManageRolePermissions.vue'),
    meta: {
      claims: [claims.UserLIST, claims.RoleREAD, claims.RoleWRITE],
      requireAllClaims: true
    }
  },
  {
    name: 'user-permissions',
    path: '/settings/user-permissions',
    component: () => import('@/pages/users/PageManageRolePermissions.vue'),
    meta: {
      claims: [claims.UserLIST, claims.RoleREAD, claims.RoleWRITE],
      requireAllClaims: true
    },
    props: {
      isUserMode: true
    }
  },
  {
    name: 'user-role-management',
    path: '/settings/user-role-management',
    component: () => import('@/pages/users/UserRoleManagementComponent.vue'),
    meta: {
      claims: claims.RoleWRITE
    },
    props: true // allows sending vue component properties via $router.push using params. e.g. { name: 'the route', params: { componentPropName: 'value' }}
  },
  {
    name: 'compliance-settings',
    path: '/application-settings/finder-settings',
    component: () => import('@/pages/settings/ComplianceSettingsComponent.vue'),
    meta: {
      claims: [
        claims.ComplianceDashboard,
        claims.ComplianceAllRequests,
        claims.ComplianceNewSubjectRequest,
        claims.ComplianceEditSubjectRequest,
        claims.ComplianceStartMatching,
        claims.ComplianceDeleteSubjectRequest,
        claims.ComplianceDuplicateRequest,
        claims.ComplianceViewSubjectResults,
        claims.ComplianceViewRequestDetails,
        claims.ComplianceFileContentReviewChangeMatchStatus,
        claims.ComplianceExportReport,
        claims.ComplianceCreateReport,
        claims.ComplianceResultsComments,
        claims.CompliancCompleteSubjectRequest,
        claims.ComplianceExportResults,
        claims.ComplianceReviewFileContent,
        claims.ComplianceViewAllReports,
        claims.ComplianceErasureChecklist,
        claims.ComplianceErasureEdit,
      ],
    }
  },
  {
    name: 'dai-settings',
    path: '/application-settings/dai-settings',
    component: () => import('@/pages/settings/DAISettingsComponent.vue'),
    meta: {
      claims: claims.AccessSystemAdminSettings
    }
  }
]
